<template lang="pug">
.container.growToPage
  .contentGrid
    div
      .field.is-grouped.is-grouped-centered
        h2.is-size-2.title SOLO - Pegaxy Renter Approval
      .box#ipcheckBox
        .field
          p.has-text-centered.has-text-danger.has-text-weight-bold 5% PROFIT SHARE. DO NOT APPLY IF YOU AREN'T OK WITH THAT
        form.login(@submit.prevent="login" v-if="!success")
          b-field(label="Name" label-position="on-border")
            b-input(type="text" placeholder="John" v-model="info.username" required)
          b-field(label="Discord ID" label-position="on-border")
            b-input(type="text" placeholder="john#1234" v-model="info.discord_id" required)
          b-field(label="Metamask Address" label-position="on-border")
            b-input(type="text" placeholder="0xcc1b9517460d8ae86fe576f614d091fca65a28fc" v-model="info.metamask" required)
          b-field(label="Person who sent you this link" label-position="on-border")
            b-input(type="text" placeholder="Bill" v-model="info.ref" required)

          .field.is-grouped.is-grouped-centered
            .control
              p.help.is-danger {{loginError}}
          .field.is-grouped.is-grouped-centered
            .control
              button.button.is-success(type="submit" :class="submitButton.classes") Submit
        .field(v-if="success")
          p.has-text-centered.has-text-weight-bold Registration completed.
          p.has-text-centered I will send you a dm on Discord if you are successful. DO NOT DM ME.
          p.has-text-centered You must be a member of the official Pegaxy discord to be eligible.

</template>

<style scoped>
.contentGrid {
    display: grid;
    place-items: center;
    height: 100%;
}
#loginBox {
    height: 100%;
    width: 400px;
    max-width: 95vw;
}
</style>

<script>
export default {
    name: "Renters",
    data: function () {
        return {
            loginPending: false,
            loginError: null,
            success: false,
            info: {
                discord_id: null,
                metamask: null,
                username: null,
                ref: null,
            }
        }
    },
    methods: {
        login: async function () {
            if (this.loginPending) return;
            // Do the login
            this.loginPending = true;
            this.loginError = null;
            try {
                await this.$api.pegaid(this.info);
                this.loginPending = false;
                this.success = true;
            } catch (e) {
                this.$log.debug("Register failed");
                this.$log.debug(e.response);
                this.loginError = e.response.data.err;
                this.loginPending = false;
            }
        },
    },
    computed: {
        submitButton: function () {
            return {
                classes: {
                    'is-loading': this.loginPending
                }
            }
        }
    }
}
</script>


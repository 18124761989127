<template lang="pug">
#mainblock
  p(v-if="$user.activeItem == null || !$user.activeItem.results") NO ACTIVE SEARCH
  div(v-else)

    p.has-text-centered {{`TOTAL RESULTS ${$user.activeItem.results.total}, FILTERED ${$user.activeItem.results.axies.length}`}}
    b-field(label="G Score" label-position="on-border")
      b-slider(v-model="minGScore" :min="0" :max="100" type="is-primary")
    .flexDiv(v-for="a in filteredResults")
      b-tooltip(type="is-dark")
        a.axieResult(:href="a.link" target="_blank")
          img.axieIcon(:src="$const.icons[a.class.toLowerCase()]")
          p {{resultString(a)}}
        <template v-slot:content>
          .genegrid
            p(v-for="p in parseGeneList(a.parts)" :style="{ color: p.colour}") {{p.name}}
          p {{timeToTimeAgo(a.listedTime)}}
          p {{remainingAuctionData(a)}}
        </template>
</template>

<script>
export default {
    name: "Results",
    data () {
        return {
            minGScore: 0,
        }
    },
    watch: {
        "$user.activeItem": function (newVal) {
            this.minGScore = 0;
        }
    },
    methods: {
        parseGeneList: function (p) {
            let r = [];
            const genes = ['d', 'r1', 'r2'];
            for (let i of this.$const.axie.allParts) {
                for (let g of genes) {
                    r.push({
                        name: p[i][g].name,
                        colour: this.$const.axie.colours[p[i][g].cls]
                    });
                }
            }
            return r;
        },
        resultString: function (a) {
            //`${a.price.toFixed(4)} (${a.breedCount} BC) - ${a.id}`
            let s = `${a.price.toFixed(4)} (${a.breedCount} BC`;
            s += a.gScore == null ? '' : ` - G ${a.gScore.toFixed(2)}`;
            s += `) - ${a.id}`;

            return s;
        },
    },
    computed: {
        filteredResults: function () {
            return this.$user.activeItem.results.axies.filter((a) => {
                if (a.gScore == null || a.gScore == 0) return true;
                if (a.gScore >= this.minGScore) return true;

                return false;
            }).sort((a, b) => {
                if (a.price < b.price) return -1;
                if (a.price > b.price) return 1;
                if (a.gScore < b.gScore) return -1;
                if (a.gScore > b.gScore) return 1;
                return 0;
            });
        }
    }
}
</script>

<style scoped>
.genegrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    column-gap: 5px;
}
a.axieResult {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
}
.flexDiv {
    display: flex;
}

</style>

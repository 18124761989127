<template lang="pug">
#mainblock
  span(:class="refreshDotClasses").refreshDot ⬤

  .subtitle.has-text-centered.is-underlined NEW LISTINGS
  .flexDiv(v-for="a in $user.recentListings")
    b-tooltip(type="is-dark")
      a.axieResult(:href="a.match.link" target="_blank")
        img.axieIcon(:src="$const.icons[a.match.class.toLowerCase()]")
        p {{newListingText(a)}}
      <template v-slot:content>
        .genegrid
          p(v-for="p in parseGeneList(a.match.parts)" :style="{color: p.colour}") {{p.name}}
        p {{timeToTimeAgo(a.match.listedTime)}}
      </template>
</template>

<script>


export default {
    name: "RecentListings",
    data () {
        return {
            ip_address: "N/A",
            server_response: "N/A",
            timerVar: null,
            statusOK: false,
            animateDot: false,
        }
    },
    mounted: function () {
        this.restartTimer();
    },
    watch: {
        "$user.lastListingReceivedTime": function () {
            // console.log('lst listing changed');
            // cancel the danger timer
            this.restartTimer();

            this.statusOK = true;
            this.animateDot = true;
            setTimeout(() => this.animateDot = false, 3000);

            // Flash the light
        }
    },
    methods: {
        restartTimer: function () {
            clearTimeout(this.timerVar);
            this.timerVar = setTimeout(() => {
                console.log("timeout, connection is dead");
                // set icon to red
                this.animateDot = false;
                this.statusOK = false;



            }, 30000);
        },
        parseGeneList: function (p) {
            let r = [];
            const genes = ['d', 'r1', 'r2'];
            for (let i of this.$const.axie.allParts) {
                for (let g of genes) {
                    r.push({
                        name: p[i][g].name,
                        colour: this.$const.axie.colours[p[i][g].cls]
                    });
                }
            }
            return r;
        },
        newListingText: function (axie) {
            //`${a.match.price.toFixed(4)} (${a.match.breedCount} BC) - ${listNameString(a)}`
            let s = `${axie.match.price.toFixed(4)} (${axie.match.breedCount} BC`;
            s += axie.match.gScore == null ? '' : ` - G ${axie.match.gScore.toFixed(2)}`;
            s += `) - ${this.listNameString(axie)}`;
            return s;
        },
        listNameString: function (p) {
            let s = "";
            if (p.search.searchName != null && p.search.searchName != "") {
                s += p.search.searchName;
            } else {
                let f = p.search.parts.d.map(a => a.name).join(', ');
                s += `${p.search.class == null ? 'Any' : p.search.class} - ${f}`;
            }
            return s;
        },
    },
    computed: {
        refreshDotClasses: function () {
            return {
                goodDot: this.statusOK,
                badDot: !this.statusOK,
                animatedDot: this.animateDot,
            }
        }
    }
}
</script>

<style scoped>
.genegrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    column-gap: 5px;
}

.refreshDot {
    position: absolute;
    font-size: 20px;

}

.goodDot {
    color: limegreen;

}
.badDot {
    color: red;
}
.animatedDot {
    animation: blink 3s;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


</style>

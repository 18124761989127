<template lang="pug">
#mainblock
  .container
    p.has-text-danger.is-danger.has-text-centered.has-text-weight-bold(v-if="errText") {{errText}}
    .box
      GSearch
    .columns
      .column.is-one-third
        List(list-title="Recent G-Scans" list-key="gScanHistory" list-index="gScanIndex")
      .column.is-two-thirds
        .box
          GScanResults




</template>

<script>
import List from "./List";
import GSearch from "./GSearch";
import GScanResults from "./GScanResults";

export default {
    name: "GScan",
    data () {
        return {
            errText: null,
        }
    },
    components: {
        List,
        GSearch,
        GScanResults,
    },
    activated: async function () {
        if (!this.$user.gScanLoaded) {
            try {
                this.errText = null;
                await this.$api.gscan.get_history();
                await this.$api.openSocket();
                this.$user.gScanLoaded = true;
            } catch (e) {
                console.log(e);
                console.log(e.response.data)
                if (e.response.data && e.response.data.err) {
                    this.errText = e.response.data.err;
                } else {
                    this.errText = `${e.message} - ${e.response.statusText}`;
                }
            }
        }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>

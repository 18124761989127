<template lang="pug">
#app
  #nav
    navigation
  #inner-content
    keep-alive
      router-view
  #foot
    vueFoot
</template>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
html, body {
    height: 100%;
    margin: 0;
}
#app {
    display: flex;
    flex-flow: column;
    height: 100%;
}
#nav {
    flex: 0 1 auto;
}
#foot {
    flex: 0 1 50px;
}
#inner-content {
    flex: 1 1 auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.container.growToPage {
    height: 100%;
}

a.panel-block.is-active {
    border-left: 1px solid black;
    background-color: hsl(0, 0%, 86%);
}

.genegrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    column-gap: 5px;
}
a.axieResult {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
}
.flexDiv {
    display: flex;
    align-items: center;
}
img.axieIconSmall {
    height: 14px;
    padding-right: 2px;
}
img.axieIcon {
    height: 18px;
    padding-right: 4px;
}




// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>


<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
export default {
    name: "app",
    components: {
        Navigation,
        vueFoot: Footer
    },
    data () {
        return {
            msg: 'Welcome to the VUE JS template'
        }
    },
    methods: {
        waitSecondsAsync: async function (sec) {
            return new Promise((resolve) => {
                setTimeout(resolve, sec*1000);
            });
        },
        test: async function () {
            await this.waitSecondsAsync(2);
            console.log("Finished waiting");
        }
    }
}
</script>

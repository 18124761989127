<template lang="pug">
#mainblock
  p(v-if="$user.activeGScan == null || !$user.activeGScan.results") NO ACTIVE GSCAN
  div(v-else)

    p.has-text-centered {{`TOTAL RESULTS ${$user.activeGScan.results.total}, FILTERED ${$user.activeGScan.results.axies.length}`}}
    b-field(grouped)
      b-field.flexDiv
        .label.no-margin.padright gScore
        b-switch(v-model="priceSort")
        .label.no-margin.padright Price
      b-field(label="G Score" label-position="on-border" expanded)
        b-slider(v-model="minGScore" :min="0" :max="100" type="is-primary")

    .flexDiv(v-for="a in filteredResults")
      b-tooltip(type="is-dark")
        a.axieResult(:href="a.link" target="_blank")
          img.axieIcon(:src="$const.icons[a.class.toLowerCase()]")
          p {{resultString(a)}}
        <template v-slot:content>
          .genegrid
            p(v-for="p in parseGeneList(a.parts)" :style="{ color: p.colour}") {{p.name}}
          p {{timeToTimeAgo(a.listedTime)}}
          p {{remainingAuctionData(a)}}
        </template>
</template>

<script>
export default {
    name: "GScanResults",
    data () {
        return {
            minGScore: 0,
            priceSort: true,
        }
    },
    watch: {
        "$user.activeGScan": function (newVal) {
            this.minGScore = 0;
        }
    },
    methods: {
        parseGeneList: function (p) {
            let r = [];
            const genes = ['d', 'r1', 'r2'];
            for (let i of this.$const.axie.allParts) {
                for (let g of genes) {
                    r.push({
                        name: p[i][g].name,
                        colour: this.$const.axie.colours[p[i][g].cls]
                    });
                }
            }
            return r;
        },
        resultString: function (a) {
            //`${a.price.toFixed(4)} (${a.breedCount} BC) - ${a.id}`
            return `${a.price.toFixed(4)} - G ${a.gScore.toFixed(2)} - ${a.correctD}/6 D - ${a.correctR1}/6 R1 - ${a.correctR2}/6 R2 - ${a.stats.speed}spd - ${a.id}`;
        }
    },
    computed: {
        filteredResults: function () {
            return this.$user.activeGScan.results.axies.filter((a) => {
                if (a.gScore == null || a.gScore == 0) return true;
                if (a.gScore >= this.minGScore) return true;

                return false;
            }).sort((a, b) => {
                if (this.priceSort && a.price < b.price) return -1;
                if (this.priceSort && a.price > b.price) return 1;
                if (!this.priceSort && a.gScore < b.gScore) return 1;
                if (!this.priceSort && a.gScore > b.gScore) return -1;
                return 0;
            });
        }
    }
}
</script>

<style scoped>
.genegrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    column-gap: 5px;
}
a.axieResult {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
}
.padright {
    margin: 0;
    padding-right: 10px;
}

</style>

import axios from 'axios';

const SERVER_URL = (process.env.NODE_ENV === 'production' ? `${process.env.LIVE_URL}` : `localhost:3000`);
const API_URL = (process.env.NODE_ENV === 'production' ? `https://${SERVER_URL}/api/` : `http://${SERVER_URL}/api/`);
const SOCKET_URL = (process.env.NODE_ENV === 'production' ? `wss://${SERVER_URL}` : `ws://${SERVER_URL}`);
const server = axios.create({
    baseURL: API_URL
});

let socket = null;

function wrapper (Vue) {
    // Check the userstore for existing auth tokens
    if (Vue.user.is_authenticated) {
        server.defaults.headers.common['x-access-token'] = Vue.user.token;
    }

    return {

        // Open a socket
        openSocket: async function () {
            if (socket != null && socket.readyState === WebSocket.OPEN) {
                Vue.$log.debug(`Socket already open`);
                return;
            }


            socket = new WebSocket(SOCKET_URL);
            socket.onopen = function (e) {
                Vue.$log.debug(`Socket open`);
                // Vue.$log.debug(e);
            };

            socket.onmessage = function (e) {
                Vue.$log.debug(`Socket message recieved`);
                let parsedData = JSON.parse(e.data);
                // Vue.$log.debug(parsedData);
                if (parsedData.type == 'newListings') {
                    Vue.user.checkNewListings(parsedData.data);
                }

                if (parsedData.type == 'grinderResult') {
                    Vue.user.addGrindResult(parsedData.data);
                }
            };

            socket.onclose = function (e) {
                Vue.$log.debug(`Socket closed, clean = ${e.wasClean}`);

                // Try to reconnect after 2sec
                if (Vue.user.is_authenticated) {
                    // Reconnect
                    setTimeout(async function () {
                        await Vue.api.openSocket();

                    }, 2000);
                }
            }
        },

        closeSocket: async function () {
            if (socket != null) {
                socket.close();
            }
        },

        // PEGA
        pegaid: async function (creds) {
            let res = await server.post('/pega/register', creds);
        },

        // Auth
        login: async function (creds) {
            let res = await server.post('/auth/login', creds);
            Vue.$log.debug(`Successful login: token = ${res.data.token}`);

            let token = res.data.token;
            // Update user store
            Vue.user.token = token;
            // Push it to localstorage
            localStorage.setItem('user-jwt', token);
            // Update the axios default header
            server.defaults.headers.common['x-access-token'] = token;
            Vue.$log.debug("Successful login");
            Vue.$log.debug(Vue.user.info);
        },
        logout: async function () {
            // Remove it from localstorage
            Vue.user.token = null;
            localStorage.removeItem('user-jwt');
            delete server.defaults.headers.common['x-access-token'];

            // Clear the store
            await Vue.user.logout();

            // Close the websocket
            await Vue.api.closeSocket();

            Vue.$log.debug("Successful logout");
        },

        send_search: async function (criteria) {
            Vue.$log.debug("Sending search criteria");
            Vue.$log.debug(criteria);

            // Null empty text fields
            if (criteria.resultsCap == "") criteria.resultsCap = null;
            if (criteria.maxPrice == "") criteria.maxPrice = null;
            if (criteria.searchName == "") criteria.searchName = null;

            delete criteria.rsyn;

            // Need to clone it
            let c = {
                search: JSON.parse(JSON.stringify(criteria))
            }
            let res = await server.post('search', c.search);
            c.results = res.data;
            Vue.user.addSearch(c);
        },

        get_inventory: async function () {
            Vue.$log.debug("Sending inventory request");

            let res = await server.get('inventory');
            return res.data;
        },

        send_mine: async function (criteria, ronin) {
            Vue.$log.debug("Sending my axies criteria");
            Vue.$log.debug(criteria);

            // Null empty text fields
            if (criteria.resultsCap == "") criteria.resultsCap = null;
            if (criteria.maxPrice == "") criteria.maxPrice = null;
            if (criteria.searchName == "") criteria.searchName = null;

            delete criteria.rsyn;

            // Need to clone it
            let c = {
                search: JSON.parse(JSON.stringify(criteria))
            }
            let res = await server.post('mine', {
                search: c.search,
                ronin
            });
            c.results = res.data;
            Vue.user.addSearch(c);
        },

        delete_search: async function (criteria) {
            Vue.$log.debug("Deleting search criteria");
            Vue.$log.debug(criteria);

            // Clone it
            let s = JSON.parse(JSON.stringify(criteria));
            await server.post('delete', s);
            Vue.user.deleteSearch(s);
        },

        get_history: async function () {
            Vue.$log.debug("Getting search history");
            let res = await server.get('history');
            console.log(res);

            for (let i of res.data) {
                Vue.user.addSearch({search: i});
            }
        },

        breed: {
            submit: async function (search, parent1, parent2) {
                Vue.$log.debug("Sending breed sim criteria");
                Vue.$log.debug(`${parent1} - ${parent2}`);

                let res = await server.post('/grind/pair', {
                    search,
                    parent1,
                    parent2
                });
                return res.data;
            },
        },

        grind: {
            submit: async function (criteria) {
                if (socket == null) {
                    throw new Error("Socket not open, cannot grind");
                }
                Vue.$log.debug("Sending grinder criteria");
                Vue.$log.debug(criteria);

                // Null empty text fields
                if (criteria.maxPrice == "") criteria.maxPrice = null;
                if (criteria.searchName == "") criteria.searchName = null;

                // Need to clone it
                let c = {
                    search: JSON.parse(JSON.stringify(criteria))
                }
                socket.send(JSON.stringify({
                    token: Vue.user.token,
                    ...c
                }));
                Vue.user.addGScan(c);
            },

            regrind: async function (criteria) {
                Vue.$log.debug("Sending regrind criteria");
                Vue.$log.debug(criteria);

                if (criteria.minProfit == "") criteria.minProfit = null;

                // Need to clone it
                let res = await server.post('/grind/regrind', criteria);
                console.log(res);
                for (let a of res.data) {
                    Vue.user.addGrindResult(a);
                }
            },
        },

        gscan: {
            submit: async function (criteria) {
                Vue.$log.debug("Sending gscan criteria");
                Vue.$log.debug(criteria);

                // Null empty text fields
                if (criteria.maxPrice == "") criteria.maxPrice = null;
                if (criteria.searchName == "") criteria.searchName = null;

                // Need to clone it
                let c = {
                    search: JSON.parse(JSON.stringify(criteria))
                }
                let res = await server.post('/scan/search', c.search);
                c.results = res.data;
                Vue.user.addGScan(c);
            },

            get_history: async function () {
                Vue.$log.debug("Getting gScan history");
                let res = await server.get('/scan/history');
                console.log(res);

                for (let i of res.data) {
                    Vue.user.addGScan({search: i});
                }
            },

            delete_gscan: async function (criteria) {
                Vue.$log.debug("Deleting gScan criteria");
                Vue.$log.debug(criteria);

                // Clone it
                let s = JSON.parse(JSON.stringify(criteria));
                await server.post('/scan/delete', s);
                Vue.user.deleteGScan(s);
            },
        },


        sales: {
            getTopSales: async function (criteria, page) {
                Vue.$log.debug("Getting top sale history");
                let res = await server.post('/sales/top', {
                    criteria,
                    page
                });
                return res.data;
            }
        },

        breedtracker: {
            getRecentBreeds: async function (page) {
                Vue.$log.debug("Getting recent breed history");
                let res = await server.get('/breedtracker/breeds', {
                    params: {
                        page
                    },
                });
                return res.data;
            },
            getTotalPL: async function () {
                Vue.$log.debug("Getting total PL");
                let res = await server.get('/breedtracker/totalpl');
                return res.data;
            },
            savePairData: async function (pair) {
                Vue.$log.debug("Sending pair data to save");

                let res = await server.post('/breedtracker/save', {
                    pair,
                });
            },
        },

        pegatracker: {
            getRecentBreeds: async function (page) {
                Vue.$log.debug("Getting pega recent breed history");
                let res = await server.get('/pegatracker/breeds', {
                    params: {
                        page
                    },
                });
                return res.data;
            },
            getTotalPL: async function () {
                Vue.$log.debug("Getting pega total PL");
                let res = await server.get('/pegatracker/totalpl');
                return res.data;
            },
            savePairData: async function (pair) {
                Vue.$log.debug("Sending pega pair data to save");

                let res = await server.post('/pegatracker/save', {
                    pair,
                });
            },
        }
    }
}

export default wrapper;

<template lang="pug">
#mainblock
  .container
    .box
      p.title.has-text-centered Changelog
      hr

      p 2021/12/13 - Added delete buttons for search/scan
      p 2021/12/04 - Added total P/L to breed tracker page
      p 2021/12/03 - Search and Gscan now show additional auction data
      p 2021/11/23 - Added breed history page
      p 2021/11/22 - Hover over # breeds for breed-by-breed ROI calculations
      p 2021/11/15 - Added Footer and subscription status
      p 2021/11/15 - Fixed new listings sometimes showing twice
      p 2021/11/14 - Added "My Axies" search. Enter ronin address and get results in your wallet that match (useful for showing the gscore of your own axies)
      p 2021/11/14 - Made the part selectors use different components, hopefully should solve the lag problem that occurs sometimes
      p 2021/11/14 - Added this changelog
</template>

<script>
    export default {
        name: "Changelog"
    }
</script>

<style scoped>

</style>

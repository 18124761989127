<template lang="pug">
#mainblock
  .container
    .box
      p.title.has-text-centered Inventory

      b-field(grouped position="is-centered")
        b-button(type="is-link" @click="loadInventory") REFRESH
      p.has-text-centered.has-text-danger {{loadText}}

      .columns
        .column.is-half
          p.subtitle.has-text-centered Axies - {{axieList.length}}
          .center-stuff
            .flexDiv(v-for="a in axieList")
              b-tooltip(type="is-dark")
                a.axieResult(:href="a.link" target="_blank")
                  img.axieIcon(:src="$const.icons[a.class.toLowerCase()]")
                  p {{resultString(a)}}
                template(v-slot:content="")
                  .genegrid
                    p(v-for="p in parseGeneList(a.parts)" :style="{ color: p.colour}") {{p.name}}
                  p {{timeToTimeAgo(a.listedTime)}}
                  p {{remainingAuctionData(a)}}

        .column.is-half
          p.subtitle.has-text-centered Eggs - {{eggList.length}}
          .center-stuff
            .flexDiv(v-for="a in eggList")
              a.axieResult(:href="a.link" target="_blank")
                img.axieIcon(:src="$const.icons[a.sireClass.toLowerCase()]")
                img.axieIcon(:src="$const.icons[a.matronClass.toLowerCase()]")
                p {{eggInfo(a)}}


</template>

<script>

export default {
    name: "Inventory",
    data () {
        return {
            inventoryData: [],
            didInitialLoad: false,
            savePending: false,
            loadText: null,
        }
    },
    activated: async function () {
        if (!this.didInitialLoad) {
            await this.loadInventory();
            this.didInitialLoad = true;
        }
    },
    methods: {
        loadInventory: async function () {
            this.loadText = null;
            this.inventoryData = [];
            this.inventoryData = await this.$api.get_inventory();
            if (this.inventoryData.length == 0) {
                this.loadText = "No results";
            } else {
                this.loadText = `${this.inventoryData.length} total axies`;
            }

            // for (let a of this.inventoryData) {
            //     console.log(a.id);
            // }
            // console.log(this.inventoryData);
        },
        resultString: function (a) {
            //`${a.price.toFixed(4)} (${a.breedCount} BC) - ${a.id}`
            let s = `${a.price.toFixed(4)} (${a.breedCount} BC`;
            s += a.gScore == null ? '' : ` - G ${a.gScore.toFixed(2)}`;
            s += `) - ${a.id}`;

            return s;
        },
        eggInfo: function (a) {
            let timeNow = Math.floor(new Date().getTime() / 1000);
            // Minus 5 days
            timeNow = timeNow - 60*60*24*5;

            if (a.birthDate < timeNow) {
                return `${a.id} - Hatching: NOW!`;
            }

            let seconds = a.birthDate - timeNow;
            let st = `${a.id} - Hatching: `;

            let days = seconds / 86400;
            if (days > 1) {
                st += `${Math.floor(days)} ${days > 2 ? 'days' : 'day'}, `;
                seconds = seconds % 86400;
            }
            let hours = seconds / 3600;
            if (hours > 1) {
                st += `${Math.floor(hours)} ${hours > 2 ? 'hours' : 'hour'}, `;
                seconds = seconds % 3600;
            }
            let minutes = seconds / 60;
            st += `${Math.floor(minutes)} ${Math.floor(minutes) == 1 ? 'minute' : 'minutes'}`;

            return st;
        },
    },
    computed: {
        eggList: function () {
            return this.inventoryData.filter(a => a.stage != 4).sort((a, b) => {
                if (a.birthDate < b.birthDate) return -1;
                return 1;
            });
        },
        axieList: function () {
            return this.inventoryData.filter(a => a.stage == 4).sort((a, b) => {
                if (a.class < b.class) return -1;
                if (a.class > b.class) return 1;
                if (a.price < b.price) return -1;
                return 1;
            });
        }
    }
}
</script>

<style scoped>
.center-stuff {
    display: grid;
    justify-items: center;
}
</style>

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Index from '../components/Index';
import About from '../components/About';
import NotFound from '../components/NotFound';
import Login from '../components/Login';
import SaleHistory from "../components/SaleHistory";
import GScan from "../components/GScan";
import BreedSim from "../components/BreedSim";
import BreedTracker from "../components/BreedTracker";
import Inventory from "../components/Inventory";
import PegaTracker from "../components/PegaTracker";
import IPCheck from "../components/IPCheck";

const ifNotAuthenticated = (to, from, next) => {
    if (!Vue.user.is_authenticated) {
        next();
    } else {
        next('/search');
    }
};

const ifAuthenticated = (to, from, next) => {
    if (Vue.user.is_authenticated) {
        next();
    } else {
        next('/');
    }
};

const routes = [
    {
        path: '/search',
        component: Index,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/sales',
        component: SaleHistory,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/gscan',
        component: GScan,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/breed',
        component: BreedSim,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/inventory',
        component: Inventory,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/breedtracker',
        component: BreedTracker,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/pegatracker',
        component: PegaTracker,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/changelog',
        component: About,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/',
        component: Login,
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/renters',
        component: IPCheck,
    },
    {
        path: '*',
        component: NotFound,
        beforeEnter: ifAuthenticated
    }
];

export default new Router({
    mode: 'history',
    routes
});

<template lang="pug">
#mainblock
  b-collapse(v-model="searchOpen")
    .columns
      .column.is-half
        b-field(label="Classes" label-position="on-border")
          b-taginput(v-model="criteria.classes" :data="filteredClasses" @typing="getFilteredClasses" autocomplete)

        b-field(label="Individual Parts" label-position="on-border" grouped)
          b-autocomplete(v-model="partSearch" :data="filteredParts" field="name" @select="option => (acPart = option)" clearable open-on-focus expanded)
          p.control
            b-button.is-success(:class="buttonClasses" :disabled="!isPartValid" @click="addPart('d')") D
          p.control
            b-button.is-info(:class="buttonClasses" :disabled="!isPartValid" @click="addPart('r1')") R1
          p.control
            b-button.is-warning(:class="buttonClasses" :disabled="!isPartValid" @click="addPart('r2')") R2
          p.control
            b-button.is-danger(:class="buttonClasses" :disabled="!isPartValid" @click="addGoalPart('g')") G

        b-field(grouped)
          b-field(label="Add all class parts" label-position="on-border" expanded)
            b-select(placeholder="Any" v-model="cpClass" expanded)
              option(v-for="c in $const.axie.classes" :value="c" :key="c") {{c}}
          b-field(label="Type" label-position="on-border")
            b-select(placeholder="Any" v-model="cpPart")
              option(v-for="c in $const.axie.allParts" :value="c" :key="c") {{c}}
          p.control
            b-button.is-success(:class="buttonClasses" :disabled="!isClassPartValid" @click="addClassParts('d')") D
          p.control
            b-button.is-info(:class="buttonClasses" :disabled="!isClassPartValid" @click="addClassParts('r1')") R1
          p.control
            b-button.is-warning(:class="buttonClasses" :disabled="!isClassPartValid" @click="addClassParts('r2')") R2
          p.control
            b-button.is-danger(:class="buttonClasses" :disabled="!isClassPartValid" @click="addClassGoalParts('g')") G

        b-field(label="Breed Count" label-position="on-border")
          b-slider(v-model="criteria.breedCount" :min="0" :max="7" type="is-danger" ticks)

        b-field(label="Pureness" label-position="on-border")
          b-slider(v-model="criteria.pureness" :min="0" :max="6" type="is-warning" ticks)

        b-field(label="Purity" label-position="on-border")
          b-slider(v-model="criteria.purity" :min="0" :max="100" type="is-warning")
        b-field(label="G score" label-position="on-border")
          b-slider(v-model="criteria.gScore" :min="0" :max="100" type="is-warning")

        b-field(label="R1 Synergy" label-position="on-border")
          b-slider(v-model="criteria.r1syn" :min="0" :max="4" type="is-warning" ticks)
        b-field(label="R2 Synergy" label-position="on-border")
          b-slider(v-model="criteria.r2syn" :min="0" :max="4" type="is-warning" ticks)

        b-field(label="Speed" label-position="on-border")
          b-slider(v-model="criteria.speed" :min="27" :max="61")
        b-field(label="Health" label-position="on-border")
          b-slider(v-model="criteria.hp" :min="27" :max="61")
        b-field(label="Morale" label-position="on-border")
          b-slider(v-model="criteria.morale" :min="27" :max="61")
        b-field(label="Skill" label-position="on-border")
          b-slider(v-model="criteria.skill" :min="27" :max="61")

        b-field(label="Search Name" label-position="on-border")
          b-input(placeholder="Dual blade breeders" v-model="criteria.searchName")

        b-field(label="Max new listing price" label-position="on-border" grouped)
          b-input(placeholder="0.1" v-model="criteria.maxPrice" type="number" min="0" step="0.001")
          b-switch(v-model="criteria.checkNew") Check new listings

        b-field(label="Max results" label-position="on-border" grouped)
          b-input(placeholder="Increments of 100" v-model="criteria.resultsCap" type="number" min="0")

        b-field(label="Ronin address" label-position="on-border")
          b-input(placeholder="ronin:12345678901234567890" v-model="ronin")

      .column.is-half
        nav.level
          .level-item
            p.subtitle.has-text-centered.is-underlined Selected parts
          .level-right
            .level-item
              b-switch(v-model="showG") Toggle G Parts

        .partSection(v-for="p in $const.axie.allParts" v-if="selectedParts[p] != null")
          b-field.is-capitalized(:label="p")
          b-field(v-for="g in Object.keys(selectedParts[p])" grouped)
            .control.is-normal
              p.is-capitalized {{g}}:
            b-taglist
              b-tag(v-for="x in selectedParts[p][g]" closable type="is-dark" close-type="is-danger" @close="removePart(g, x.id)")
                .flexDiv
                  img.axieIconSmall(:src="$const.icons[x.cls]")
                  p {{x.name}}
    hr

  nav.level
    .level-item
      b-button(@click="toggleSearch") TOGGLE
    .level-item
      b-button(type="is-warning" @click="resetCriteria") CLEAR
    .level-item
      b-button(type="is-success" :disabled="searchPending" @click="submitSearch") SEARCH
    .level-item
      b-button(type="is-link" @click="searchMyAxies") MY AXIES
    .level-item
      b-button(type="is-danger" @click="deleteSearch") DELETE

  p.help.is-danger.has-text-centered(v-if="searchError") {{searchError}}

</template>

<style scoped>
option {
    text-transform: capitalize;
}
.partSection {
    padding-bottom: 1rem;
}
</style>

<script>


// Don't nest objects other than parts
const defaultCriteria = {
    classes: [],
    speed: [27, 61],
    hp: [27, 61],
    morale: [27, 61],
    skill: [27, 61],
    breedCount: [0, 7],
    pureness: 0,
    purity: 0,
    gScore: 0,
    r1syn: 0,
    r2syn: 0,
    parts: {
        d: [],
        r1: [],
        r2: [],
    },
    goalParts: {
        g: [],
    },
    resultsCap: null,
    searchName: null,
    checkNew: false,
    maxPrice: null,
}


export default {
    name: "Search",
    data () {
        return {
            ronin: "",
            acPart: null,
            showG: true,
            classDropdown: null,
            filteredClasses: [],
            partSearch: "",
            searchOpen: true,
            cpClass: null,
            cpPart: null,
            criteria: JSON.parse(JSON.stringify(defaultCriteria)),
            searchPending: false,
            searchError: null,
        }
    },
    watch: {
        "$user.activeItem": function (newVal) {
            if (newVal != null ) {
                let newCopy = JSON.parse(JSON.stringify(newVal.search));
                let newDefault = JSON.parse(JSON.stringify(defaultCriteria));

                // Fuck backwards compatability
                for (let k of Object.keys(defaultCriteria)) {
                    if (newCopy[k] != null) {
                        this.criteria[k] = newCopy[k];
                    } else {
                        this.criteria[k] = newDefault[k];
                    }
                }

            } else {
                this.resetCriteria();
            }
            this.partSearch = "";
        }
    },
    methods: {
        addPart: function(part) {
            let p = this.acPart;
            // Check if it's already in the set
            if (!this.criteria.parts[part].map(a => a.partId).includes(p.partId)) {
                this.criteria.parts[part].push(p);
            }

            // Auto add to G as well
            this.addGoalPart('g');
        },
        addGoalPart: function(part) {
            let p = this.acPart;
            // Check if it's already in the set
            if (!this.criteria.goalParts[part].map(a => a.partId).includes(p.partId)) {
                this.criteria.goalParts[part].push(p);
            }
        },
        addClassParts: function (geneLevel) {
            // Generate the list of class parts
            let cp = Object.values(this.$const.parts).filter((p) => {
                if (p.class != this.cpClass.toLowerCase()) return false;
                if (p.type != this.cpPart) return false;
                if (p.specialGenes != "") return false;
                return true;
            });

            // Add if it's not already in the list
            for (let p of cp) {
                if (!this.criteria.parts[geneLevel].map(a => a.partId).includes(p.partId)) {
                    this.criteria.parts[geneLevel].push(p);
                }
            }

            // Auto add to G as well
            this.addClassGoalParts('g');

            this.cpPart = null;
            this.cpClass = null;

        },
        addClassGoalParts: function (geneLevel) {
            // Generate the list of class parts
            let cp = Object.values(this.$const.parts).filter((p) => {
                if (p.class != this.cpClass.toLowerCase()) return false;
                if (p.type != this.cpPart) return false;
                if (p.specialGenes != "") return false;
                return true;
            });

            // Add if it's not already in the list
            for (let p of cp) {
                if (!this.criteria.goalParts[geneLevel].map(a => a.partId).includes(p.partId)) {
                    this.criteria.goalParts[geneLevel].push(p);
                }
            }

            this.cpPart = null;
            this.cpClass = null;

        },
        removePart: function(g, id) {
            if (this.criteria.parts[g] != null) {
                this.criteria.parts[g] = this.criteria.parts[g].filter(a => a.partId != id);
            }
            if (this.criteria.goalParts[g] != null) {
                this.criteria.goalParts[g] = this.criteria.goalParts[g].filter(a => a.partId != id);
            }
        },
        submitSearch: async function () {
            this.searchPending = true;
            this.searchError = null;
            try {
                await this.$api.send_search(this.criteria);
            } catch (e) {
                console.log(e);
                console.log(e.response.data)
                if (e.response.data && e.response.data.err) {
                    this.searchError = e.response.data.err;
                } else {
                    this.searchError = `${e.message} - ${e.response.statusText}`;
                }
            }

            this.searchPending = false;
        },
        searchMyAxies: async function () {
            if (this.ronin == "") {
                this.searchError = "Enter a ronin address";
                return;
            }

            this.searchPending = true;
            this.searchError = null;
            try {
                await this.$api.send_mine(this.criteria, this.ronin);
            } catch (e) {
                console.log(e);
                if (e.response.data && e.response.data.err) {
                    this.searchError = e.response.data.err;
                } else {
                    this.searchError = `${e.message} - ${e.response.statusText}`;
                }
            }

            this.searchPending = false;
        },
        deleteSearch: async function () {
            try {
                await this.$api.delete_search(this.$user.activeItem.search);
            } catch (e) {
                console.log(e);
                if (e.response.data && e.response.data.err) {
                    this.searchError = e.response.data.err;
                } else {
                    this.searchError = `${e.message} - ${e.response.statusText}`;
                }
            }
        },
        resetCriteria: function () {
            this.criteria = JSON.parse(JSON.stringify(defaultCriteria));
            this.showG = true;
        },
        toggleSearch: function () {
            this.searchOpen = !this.searchOpen;
        },
        logout: async function () {
            await this.$api.logout();
            await this.$router.push('/');
        },
        getFilteredClasses: function (text) {
            this.filteredClasses = this.$const.axie.classes.filter(a => a.toLowerCase().includes(text.toLowerCase()));
        }
    },
    computed: {
        filteredParts: function () {
            return this.primaryParts.filter(a => {
                return a.name.toLowerCase().indexOf(this.partSearch.toLowerCase()) >= 0;
            });
        },
        primaryParts: function () {
            return Object.values(this.$const.parts)
                .filter(a => this.$const.axie.mainParts.includes(a.type) && a.specialGenes == "")
                .sort((a, b) => a.name < b.name ? -1 : 1);
        },
        isPartValid: function () {
            return this.acPart != null;
        },
        isClassPartValid: function () {
            return this.cpClass != null && this.cpPart != null;
        },
        buttonClasses: function () {
            return {
                'is-active': this.isPartValid,
            }
        },
        selectedParts: function () {
            let d = {};
            // Normal parts
            for (let g of Object.keys(this.criteria.parts)) {
                for (let p of this.criteria.parts[g]) {
                    // Check/create type (horn, mouth etc)
                    if (d[p.type] == null) {
                        d[p.type] = {};
                    }
                    // Check/create gene (d, r1, r2)
                    if (d[p.type][g] == null) {
                        d[p.type][g] = [];
                    }
                    // Actually add it
                    d[p.type][g].push({
                        name: p.name,
                        cls: p.class,
                        id: p.partId
                    });
                }
            }

            // Goal parts
            if (this.showG) {
                for (let g of Object.keys(this.criteria.goalParts)) {
                    for (let p of this.criteria.goalParts[g]) {
                        // Check/create type (horn, mouth etc)
                        if (d[p.type] == null) {
                            d[p.type] = {};
                        }
                        // Check/create gene (d, r1, r2)
                        if (d[p.type][g] == null) {
                            d[p.type][g] = [];
                        }
                        // Actually add it
                        d[p.type][g].push({
                            name: p.name,
                            cls: p.class,
                            id: p.partId
                        });
                    }
                }
            }

            return d;
        },
    }

}
</script>


<template lang="pug">
footer.footer
  .container.maxHeight
    .level.maxHeight
      .level-left
        .level-item
          p {{authedUser}}
      .level-item(v-if="$user.is_authenticated")
        p Subscription expires: {{new Date(parseInt(this.$user.info.expiry))}}
      .level-right
        .level-item
          span.icon
            i.far.fa-copyright
          span {{currentYear}} tallco.de




</template>

<style scoped>
footer.footer {
    padding: 0;
    height: 50px;
}

.maxHeight {
    height: 100%;
}
</style>

<script>
export default {
    name: "Footer",
    computed: {
        authedUser: function () {
            if (this.$user.is_authenticated) {
                return `Logged in as: ${this.$user.info.user}`;
            } else {
                return "Not Logged In";
            }
        },
        currentYear: function () {
            return (new Date()).getFullYear();
        }
    }
}
</script>

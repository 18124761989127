import Vue from 'vue';
import App from './App.vue';
import Buefy from "buefy";
import router from './router';

Vue.use(Buefy, {
    defaultIconPack: 'fas',
});

import VueLogger from 'vuejs-logger';
const isProduction = process.env.NODE_ENV === 'production';
const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};
Vue.use(VueLogger, options);

import UserStore from './plugins/store';
Vue.use(UserStore);

import api from './api';
const apiPlugin = {
    install (Vue) {
        Vue.api = api(Vue);
        Vue.prototype.$api = api(Vue);
    }
};
Vue.use(apiPlugin);

// Global functions
Vue.mixin({
    methods: {
        logError: function (e) {
            this.$log.debug(e);
            if (e instanceof Object && e.response != null) {
                this.$log.debug(e.response);
                if (e.response.data != null && e.response.data.err != null) {
                    return e.response.data.err;
                }
            }
            return 'Something went wrong =['
        },
        waitSecondsAsync: async function (sec) {
            return new Promise((resolve) => {
                setTimeout(resolve, sec * 1000);
            });
        },
        timeToTimeAgo: function (ts) {
            if (ts == null) {
                return "Not listed.";
            }
            let timeNow = Math.floor(new Date().getTime() / 1000);
            let seconds = timeNow - ts;
            let s = "Listed: ";

            let days = seconds / 86400;
            if (days > 1) {
                s += `${Math.floor(days)} ${days > 2 ? 'days' : 'day'}, `;
                seconds = seconds % 86400;
            }
            let hours = seconds / 3600;
            if (hours > 1) {
                s += `${Math.floor(hours)} ${hours > 2 ? 'hours' : 'hour'}, `;
                seconds = seconds % 3600;
            }
            let minutes = seconds / 60;
            s += `${Math.floor(minutes)} ${Math.floor(minutes) == 1 ? 'minute' : 'minutes'} ago`;

            return s;
        },
        remainingAuctionData: function (a) {
            if (a.listedTime == null) {
                return null;
            }

            // return `${a.auctionEndTime} - ${a.endingPrice}`;

            if (a.price == a.endingPrice) {
                return "At minimum price";
            }

            let timeNow = Math.floor(new Date().getTime() / 1000);
            let seconds = a.auctionEndTime - timeNow;
            let s = `⇒ ${a.endingPrice.toFixed(4)} in `;

            let days = seconds / 86400;
            if (days > 1) {
                s += `${Math.floor(days)} ${days > 2 ? 'days' : 'day'}`;
                seconds = seconds % 86400;
                return s;
            }
            let hours = seconds / 3600;
            if (hours > 1) {
                s += `${Math.floor(hours)} ${hours > 2 ? 'hours' : 'hour'}, `;
                seconds = seconds % 3600;
            }
            let minutes = seconds / 60;
            s += `${Math.floor(minutes)} ${Math.floor(minutes) == 1 ? 'minute' : 'minutes'}`;

            return s;
        },
        parseGeneList: function (p) {
            let r = [];
            const genes = ['d', 'r1', 'r2'];
            for (let i of this.$const.axie.allParts) {
                for (let g of genes) {
                    r.push({
                        name: p[i][g].name,
                        colour: this.$const.axie.colours[p[i][g].cls]
                    });
                }
            }
            return r;
        },
    }
});

new Vue({
    el: '#app',
    router,
    render: h => h(App)
});

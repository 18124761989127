import axieParts from "../../../const/parts.json";
import axieExtras from "../../../const/axie.json";
import axieIcons from "../../../assets/icons/*.svg";

class UserStore {
    constructor(Vue, options) {
        // Check for existing token in localstorage
        let localToken = localStorage.getItem('user-jwt') || '';

        // Lets make a new instance to store the data
        this.storeVM = new Vue({
            data() {
                return {
                    token: localToken === '' ? null : localToken,
                    searchHistory: [],
                    recentListings: [],
                    listIndex: 0,
                    lastListingReceivedTime: 0,
                    searchLoaded: false,
                    gScanHistory: [],
                    gScanIndex: 0,
                    gScanLoaded: false,
                    grindResults: [],
                    grindResultIndex: 0,
                };
            },
        });
    }

    get is_authenticated() {
        return this.storeVM.$data.token != null;
    }

    get info() {
        if (this.storeVM.$data.token != null) {
            return JSON.parse(atob(this.storeVM.$data.token.split('.')[1]));
        }
        return null;
    }

    get token() {
        return this.storeVM.$data.token;
    }

    set token(t) {
        this.storeVM.$data.token = t;
    }

    get searchLoaded() {
        return this.storeVM.$data.searchLoaded;
    }

    set searchLoaded(t) {
        this.storeVM.$data.searchLoaded = t;
    }

    get gScanLoaded() {
        return this.storeVM.$data.gScanLoaded;
    }

    set gScanLoaded(t) {
        this.storeVM.$data.gScanLoaded = t;
    }


    addSearch (s) {
        this.storeVM.$data.searchHistory = this.storeVM.$data.searchHistory.filter(a => JSON.stringify(a.search) != JSON.stringify(s.search));
        this.storeVM.$data.searchHistory.unshift(s);
        this.storeVM.$data.listIndex = 0;
    }

    deleteSearch (s) {
        this.storeVM.$data.listIndex = 0;
        this.storeVM.$data.searchHistory = this.storeVM.$data.searchHistory.filter(a => JSON.stringify(a.search) != JSON.stringify(s));
    }

    get searchHistory() {
        return this.storeVM.$data.searchHistory;
    }

    get listIndex() {
        return this.storeVM.$data.listIndex;
    }

    set listIndex(i) {
        this.storeVM.$data.listIndex = i;
    }

    get activeItem () {
        if (this.storeVM.$data.searchHistory.length > this.listIndex) {
            return this.storeVM.$data.searchHistory[this.storeVM.$data.listIndex];
        } else {
            return null;
        }
    }



    addGScan (s) {
        this.storeVM.$data.gScanHistory = this.storeVM.$data.gScanHistory.filter(a => JSON.stringify(a.search) != JSON.stringify(s.search));
        this.storeVM.$data.gScanHistory.unshift(s);
        this.storeVM.$data.gScanIndex = 0;
    }

    deleteGScan (s) {
        this.storeVM.$data.gScanIndex = 0;
        this.storeVM.$data.gScanHistory = this.storeVM.$data.gScanHistory.filter(a => JSON.stringify(a.search) != JSON.stringify(s));
    }

    get gScanHistory() {
        return this.storeVM.$data.gScanHistory;
    }

    get gScanIndex() {
        return this.storeVM.$data.gScanIndex;
    }

    set gScanIndex(i) {
        this.storeVM.$data.gScanIndex = i;
    }

    get activeGScan () {
        if (this.storeVM.$data.gScanHistory.length > this.gScanIndex) {
            return this.storeVM.$data.gScanHistory[this.storeVM.$data.gScanIndex];
        } else {
            return null;
        }
    }

    get grindResults() {
        return this.storeVM.$data.grindResults;
    }

    get grindResultIndex() {
        return this.storeVM.$data.grindResultIndex;
    }

    set grindResultIndex(i) {
        this.storeVM.$data.grindResultIndex = i;
    }

    addGrindResult(r) {
        this.storeVM.$data.grindResults.push(r);
    }

    clearGrindResults() {
        this.storeVM.$data.grindResults = [];
    }

    get recentListings () {
        return this.storeVM.$data.recentListings;
    }

    get lastListingReceivedTime () {
        return this.storeVM.$data.lastListingReceivedTime;
    }

    checkNewListings (data) {
        this.storeVM.$data.lastListingReceivedTime = new Date().getTime();

        // Each new listing
        for (let axie of data) {
            // Each saved search
            for (let s of this.storeVM.$data.searchHistory) {
                if (s.search.checkNew != true || !Array.isArray(s.search.classes)) continue;

                let axieCopy = getAxieWithGScore(axie, s.search);

                let match = checkSingleFilter(axieCopy, s.search);
                if (match) {
                    console.log("Got a match");
                    console.log(axieCopy);
                    this.storeVM.$data.recentListings.unshift({
                        search: JSON.parse(JSON.stringify(s.search)),
                        match: axieCopy
                    });
                }
            }
        }
    }

    logout () {
        // Clear the store
        this.storeVM.$data.token = null;
        this.storeVM.$data.searchHistory = [];
        this.storeVM.$data.recentListings = [];
        this.storeVM.$data.listIndex = 0;
        this.storeVM.$data.gScanHistory = [];
        this.storeVM.$data.gScanIndex = 0;
        this.storeVM.$data.lastListingReceivedTime = 0;
        this.storeVM.$data.searchLoaded = false;
        this.storeVM.$data.gScanLoaded = false;
        this.storeVM.$data.grindResults = [];
        this.storeVM.$data.grindResultIndex = 0;
    }

}

function getAxieWithGScore (axie, search) {
    let copy = JSON.parse(JSON.stringify(axie));

    // Calculate GScore
    // Calculate G score
    if (search != null && search.goalParts != null && search.goalParts.g != null && search.goalParts.g.length != 0) {
        copy.gScore = 0.00;
        for (let p of axieExtras.allParts) {
            let validG = search.goalParts.g.filter(x => x.type == p).map(x => x.partId);

            if (validG.length > 0) {
                // D = +75
                if (validG.includes(copy.parts[p].d.partId)) {
                    copy.gScore += 75.00;
                }

                // R1 = +18.75
                if (validG.includes(copy.parts[p].r1.partId)) {
                    copy.gScore += 18.75;
                }

                // R2 = + 6.25
                if (validG.includes(copy.parts[p].r2.partId)) {
                    copy.gScore += 6.25;
                }
            } else {
                copy.gScore += 100;
            }
        }
        copy.gScore = copy.gScore / 6;
    }

    return copy;
}

function checkSingleFilter(axie, search) {
    // Pureness
    if (search.pureness != null && search.pureness != 0 && axie.pureness != search.pureness) return false;

    // Class
    if (search.classes.length != 0 && !search.classes.includes(axie.class)) return false;

    // Breedcount
    if (axie.breedCount < search.breedCount[0] || axie.breedCount > search.breedCount[1]) return false;

    // Purity
    if (search.purity != null && axie.purity < search.purity) return false;

    // Speed
    if (axie.stats.speed < search.speed[0] || axie.stats.speed > search.speed[1]) return false;

    // Health
    if (axie.stats.hp < search.hp[0] || axie.stats.hp > search.hp[1]) return false;

    // Morale
    if (axie.stats.morale < search.morale[0] || axie.stats.morale > search.morale[1]) return false;

    // Skill
    if (axie.stats.skill < search.skill[0] || axie.stats.skill > search.skill[1]) return false;

    // R1syn
    if (search.r1syn != null && search.r1syn != 0 && axie.r1syn < search.r1syn) return false;

    // R2syn
    if (search.r2syn != null && search.r2syn != 0 && axie.r2syn < search.r2syn) return false;

    // Max Price
    if (search.maxPrice != null && search.maxPrice != "" && axie.price > search.maxPrice) return false;

    // GScore
    if (search.gScore != null && search.gScore != 0 && axie.gScore < search.gScore) return false;

    // Check parts
    for (let p of axieExtras.allParts) {
        let validD = search.parts.d.filter(x => x.type == p).map(x => x.partId);
        if (validD.length > 0) {
            if (!validD.includes(axie.parts[p].d.partId)) return false;
        }

        let validR1 = search.parts.r1.filter(x => x.type == p).map(x => x.partId);
        if (validR1.length > 0) {
            if (!validR1.includes(axie.parts[p].r1.partId)) return false;
        }

        let validR2 = search.parts.r2.filter(x => x.type == p).map(x => x.partId);
        if (validR2.length > 0) {
            if (!validR2.includes(axie.parts[p].r2.partId)) return false;
        }

    }

    return true;

}


export default {
    /**
     * Install plugin
     * @param {Vue} Vue - Vue instance
     * @param {Object} options - Options for the plugin
     */
    install: (Vue, options = {}) => {
        let d = new UserStore(Vue, options);
        let constData = {
            parts: axieParts,
            axie: axieExtras,
            icons: axieIcons,
        };
        Vue.user = d;
        Vue.prototype.$user = d;

        Vue.const = constData;
        Vue.prototype.$const = constData;
    },
};

// Usage: import UserStore from 'plugins/store'; Vue.use(UserStore);

<template lang="pug">
#mainblock
  .container
    .box
      b-field(grouped)
        b-field(label="Class" label-position="on-border" expanded)
          b-select(placeholder="Any" v-model="criteria.class" expanded)
            option(:value="null" :key="null") Any
            option(v-for="c in $const.axie.classes" :value="c" :key="c") {{c}}
        b-field(label="Mouth" label-position="on-border")
          b-select(placeholder="Any" v-model="criteria.mouth")
            option(:value="null" :key="null") Any
            option(v-for="c in partFilter('mouth')" :value="c.partId" :key="c.partId") {{c.name}}
        b-field(label="Horn" label-position="on-border")
          b-select(placeholder="Any" v-model="criteria.horn")
            option(:value="null" :key="null") Any
            option(v-for="c in partFilter('horn')" :value="c.partId" :key="c.partId") {{c.name}}
        b-field(label="Back" label-position="on-border")
          b-select(placeholder="Any" v-model="criteria.back")
            option(:value="null" :key="null") Any
            option(v-for="c in partFilter('back')" :value="c.partId" :key="c.partId") {{c.name}}
        b-field(label="Tail" label-position="on-border")
          b-select(placeholder="Any" v-model="criteria.tail")
            option(:value="null" :key="null") Any
            option(v-for="c in partFilter('tail')" :value="c.partId" :key="c.partId") {{c.name}}
        b-field(label="Sort By" label-position="on-border")
          b-select(placeholder="Any" v-model="criteria.sortMetric")
            option(v-for="c in sortMetricOptions" :value="c.v" :key="c.v") {{c.t}}

      b-field(grouped)
        b-field.flexDiv
          .label.no-margin.padright Group speed / pureness together
          b-switch(v-model="criteria.group")
        b-field(label="Speed" label-position="on-border" expanded)
          .padright
            b-slider(v-model="criteria.speed" :disabled="criteria.group" :min="27" :max="61")
        b-field.padright(label="Pureness" label-position="on-border" expanded)
          b-slider(v-model="criteria.pureness" :disabled="criteria.group" :min="0" :max="6")

      nav.level
        .level-item
          b-field(grouped)
            .control
              b-button.padleft(type="is-info" @click="loadTopSales") SEARCH
            .control
              b-button(type="is-warning" @click="resetCriteria") CLEAR
      p.help.is-danger.has-text-centered(v-if="searchError") {{searchError}}
      p.help.has-text-centered Currently tracks D sets with > 20 sales / day, or > 3 sales / day with an average price higher than 0.1 ETH.
      //p {{saleData[0]}}
      //p {{criteria}}
      //p {{lastSevenDays}}

    div(v-if="saleData.length > 0 && activeSearch.group")
      b-table(bordered hoverable narrowed :data="saleData" ref="Table" detailed custom-detail-row show-detail-icon)
        b-table-column(label="Axie" v-slot="props")
          .flexDiv
            img.axieIcon(:src="$const.icons[props.row.axie.class]")
            p {{axieString(props.row.axie.parts)}}
        b-table-column(label="Date") {{lastSevenDays[0]}}
        b-table-column(label="Sales" v-slot="props") {{props.row.records[lastSevenDays[0]].count}}
        b-table-column(label="Sales Δ" width="100" v-slot="props")
          div(:set="dd = dailyDelta('count', props.row.records, 0)")
            .tag( :class="{'is-success': dd >= 0, 'is-danger': dd < 0}") {{dd == 'N/A' ? dd : dd > 0 ? `+${dd}%` : `${dd}%`}}
        b-table-column(label="Average" v-slot="props") {{props.row.records[lastSevenDays[0]].avg.toFixed(4)}}
        b-table-column(label="Price Δ" width="100" v-slot="props")
          div(:set="dd = dailyDelta('avg', props.row.records, 0)")
            .tag( :class="{'is-success': dd >= 0, 'is-danger': dd < 0}") {{dd == 'N/A' ? dd : dd > 0 ? `+${dd}%` : `${dd}%`}}
        template(slot="detail" slot-scope="props")
          tr(v-for="(ds, i) in lastSevenDays.slice(1, daysLoaded)")
            td
            td
            td {{ds}}
            td {{props.row.records[ds] ? props.row.records[ds].count : 'N/A'}}
            td(:set="dd = dailyDelta('count', props.row.records, i+1)")
              .tag( :class="{'is-success': dd >= 0, 'is-danger': dd < 0}") {{dd == 'N/A' ? dd : dd > 0 ? `+${dd}%` : `${dd}%`}}
            td {{props.row.records[ds] ? props.row.records[ds].avg.toFixed(4) : 'N/A'}}
            td(:set="dd = dailyDelta('avg', props.row.records, i+1)")
              .tag( :class="{'is-success': dd >= 0, 'is-danger': dd < 0}") {{dd == 'N/A' ? dd : dd > 0 ? `+${dd}%` : `${dd}%`}}

    // Extra columns
    div(v-if="saleData.length > 0 && !activeSearch.group")
      b-table(bordered hoverable narrowed :data="saleData" ref="Table" detailed custom-detail-row show-detail-icon)
        b-table-column(label="Axie" v-slot="props")
          .flexDiv
            img.axieIcon(:src="$const.icons[props.row.axie.class]")
            p {{axieString(props.row.axie.parts)}}
        b-table-column(label="Speed" v-slot="props") {{props.row.axie.speed}}
        b-table-column(label="Pureness" v-slot="props") {{props.row.axie.pureness}} / 6
        b-table-column(label="Date") {{lastSevenDays[0]}}
        b-table-column(label="Sales" v-slot="props") {{props.row.records[lastSevenDays[0]].count}}
        b-table-column(label="Sales Δ" width="100" v-slot="props")
          div(:set="dd = dailyDelta('count', props.row.records, 0)")
            .tag( :class="{'is-success': dd >= 0, 'is-danger': dd < 0}") {{dd == 'N/A' ? dd : dd > 0 ? `+${dd}%` : `${dd}%`}}
        b-table-column(label="Median" v-slot="props") {{props.row.records[lastSevenDays[0]].med.toFixed(4)}}
        b-table-column(label="Median Δ" width="100" v-slot="props")
          div(:set="dd = dailyDelta('med', props.row.records, 0)")
            .tag( :class="{'is-success': dd >= 0, 'is-danger': dd < 0}") {{dd == 'N/A' ? dd : dd > 0 ? `+${dd}%` : `${dd}%`}}
        b-table-column(label="Average" v-slot="props") {{props.row.records[lastSevenDays[0]].avg.toFixed(4)}}
        b-table-column(label="Price Δ" width="100" v-slot="props")
          div(:set="dd = dailyDelta('avg', props.row.records, 0)")
            .tag( :class="{'is-success': dd >= 0, 'is-danger': dd < 0}") {{dd == 'N/A' ? dd : dd > 0 ? `+${dd}%` : `${dd}%`}}
        template(slot="detail" slot-scope="props")
          tr(v-for="(ds, i) in lastSevenDays.slice(1, daysLoaded)")
            td
            td
            td
            td
            td {{ds}}
            td {{props.row.records[ds] ? props.row.records[ds].count : 'N/A'}}
            td(:set="dd = dailyDelta('count', props.row.records, i+1)")
              .tag( :class="{'is-success': dd >= 0, 'is-danger': dd < 0}") {{dd == 'N/A' ? dd : dd > 0 ? `+${dd}%` : `${dd}%`}}
            td {{props.row.records[ds] ? props.row.records[ds].med.toFixed(4) : 'N/A'}}
            td(:set="dd = dailyDelta('med', props.row.records, i+1)")
              .tag( :class="{'is-success': dd >= 0, 'is-danger': dd < 0}") {{dd == 'N/A' ? dd : dd > 0 ? `+${dd}%` : `${dd}%`}}
            td {{props.row.records[ds] ? props.row.records[ds].avg.toFixed(4) : 'N/A'}}
            td(:set="dd = dailyDelta('avg', props.row.records, i+1)")
              .tag( :class="{'is-success': dd >= 0, 'is-danger': dd < 0}") {{dd == 'N/A' ? dd : dd > 0 ? `+${dd}%` : `${dd}%`}}

    div(v-if="saleData.length > 0")
      .flexDiv.nrText.has-text-dark.centerDiv
        a
          i.fas.fa-chevron-left(v-if="page > 0" @click="changePage(false)")
        p.is-unselectable {{page+1}}
        a
          i.fas.fa-chevron-right(@click="changePage(true)")
    p.nrText.has-text-centered.has-text-dark {{nrText}}

</template>

<script>
const defaultCriteria = {
    'class': null,
    mouth: null,
    horn: null,
    back: null,
    tail: null,
    speed: [27, 61],
    group: true,
    pureness: [0, 6],
    sortMetric: 'count',
};

export default {
    name: "SaleHistory",
    data () {
        return {
            saleData: [],
            daysLoaded: 7,
            criteria: JSON.parse(JSON.stringify(defaultCriteria)),
            activeSearch: {},
            page: 0,
            searchError: null,
            nrText: null,
        }
    },
    watch: {
        "criteria.group": function () {
            if (this.criteria.group && this.criteria.sortMetric == 'med') {
                this.criteria.sortMetric = 'count';
            }
            if (this.criteria.group) {
                this.criteria.speed = [27, 61];
                this.criteria.pureness = [0, 6];
            }
        }
    },
    methods: {
        loadTopSales: async function () {
            this.searchError = null;
            this.saleData = [];
            this.nrText = null;
            this.activeSearch = JSON.parse(JSON.stringify(this.criteria));
            this.page = 0;
            try {
                this.saleData = await this.$api.sales.getTopSales(this.activeSearch, this.page);
            } catch (e) {
                console.log(e);
                console.log(e.response.data)
                if (e.response.data && e.response.data.err) {
                    this.searchError = e.response.data.err;
                } else {
                    this.searchError = `${e.message} - ${e.response.statusText}`;
                }
            }

            if (this.saleData.length == 0) this.nrText = "No Results";
        },
        axieString: function (a) {
            return Object.values(a).map(p => this.$const.parts[p].name).join(', ');
        },
        dailyDelta: function (property, records, index) {
            if (records[this.lastSevenDays[index]] == null || records[this.lastSevenDays[index + 1]] == null) {
                return "N/A";
            }
            let d = ((records[this.lastSevenDays[index]][property] - records[this.lastSevenDays[index + 1]][property]) / records[this.lastSevenDays[index + 1]][property] * 100).toFixed(2);
            return d;
        },
        partFilter: function (partType) {
            return Object.values(this.$const.parts).filter(p => p.type == partType && p.specialGenes == "");
        },
        resetCriteria: function () {
            this.criteria = JSON.parse(JSON.stringify(defaultCriteria));
        },
        changePage: async function (pageUp) {
            this.page += pageUp ? 1 : -1;
            // Send the activesearch
            this.saleData = [];
            this.nrText = null;
            this.saleData = await this.$api.sales.getTopSales(this.activeSearch, this.page);
            if (this.saleData.length == 0) this.nrText = "No Results";
        }
    },
    computed: {
        lastSevenDays: function () {
            let timeNow = new Date();
            let utcNow =  new Date(Date.UTC(timeNow.getUTCFullYear(), timeNow.getUTCMonth(), timeNow.getUTCDate()));
            let dateStrings = [];
            for (let i = 0; i < this.daysLoaded+1; i++) {
                utcNow.setUTCDate(utcNow.getUTCDate() - 1);
                dateStrings.push(utcNow.toISOString().slice(0, 10));
            }
            return dateStrings;
        },
        sortMetricOptions: function () {
            if (this.criteria.group) {
                return [{
                    t: "Sales",
                    v: "count"
                }, {
                    t: "Average Price",
                    v: "avg"
                }];
            } else {
                return [{
                    t: "Sales",
                    v: "count"
                }, {
                    t: "Average Price",
                    v: "avg"
                }, {
                    t: "Median Price",
                    v: "med"
                }];
            }
        }
    }
}
</script>

<style scoped>
.no-margin {
    margin-bottom: 0;
}
.padleft {
    margin-left: 10px;
}
.padright {
    margin-right: 10px;
}
.padboth {
    margin-right: 10px;
    margin-left: 10px;
}
.nrText {
    font-size: 2em;
    font-weight: bold;
}
.centerDiv {
    width: 100%;
    justify-content: center;
}
</style>

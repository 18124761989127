<template lang="pug">
.navbar.is-danger
  .container
    .navbar-brand
      .navbar-item {{brand}}
      .navbar-burger(@click="triggerMobileNav", :class="mobileNavActive")
        span
        span
        span
    .navbar-menu(:class="mobileNavActive")
      .navbar-start
      template(v-if="loggedIn")
        .navbar-end
          router-link.navbar-item(to="/search"  @click.native="triggerMobileNav") Search
          router-link.navbar-item(to="/gscan"  @click.native="triggerMobileNav") G-Scan
          router-link.navbar-item(to="/breed"  @click.native="triggerMobileNav") Breed Sim
          router-link.navbar-item(to="/sales"  @click.native="triggerMobileNav") Sale History
          router-link.navbar-item(to="/breedtracker"  @click.native="triggerMobileNav") Breed Tracker
          router-link.navbar-item(to="/inventory"  @click.native="triggerMobileNav") Inventory
          router-link.navbar-item(to="/changelog"  @click.native="triggerMobileNav") Changelog
          router-link.navbar-item(to="/pegatracker"  @click.native="triggerMobileNav") PEGA Tracker
          a.navbar-item(@click="logout") Log Out
      template(v-else)
        .navbar-end
          .navbar-item
            //p Contact <b>coen#1234</b> on Discord for access
</template>

<style scoped>

</style>

<script>
export default {
    name: "Navigation",
    data: function () {
        return {
            brand: "AXIEHUNTER",
            mobileNavActive: {
                'is-active': false
            },
        }
    },
    methods: {
        triggerMobileNav: function () {
            this.mobileNavActive['is-active'] = !this.mobileNavActive['is-active'];
        },
        logout: async function () {
            await this.$api.logout();
            this.triggerMobileNav();
            await this.$router.push('/');
        }
    },
    computed: {
        loggedIn: function () {
            return this.$user.is_authenticated;
        },
    }
}
</script>


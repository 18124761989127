<template lang="pug">
#mainblock
  .container
    .box
      p.title.has-text-centered Breed tracker
      p.subtitle.has-text-centered.no-margin Total Profit/Loss on completed trades: {{pl.total.toFixed(4)}} ETH
      p.subtitle.has-text-centered 14 Day P/L: {{pl.shortPL.toFixed(4)}} ETH

      div(v-for="bp in breedData")
        .columns
          .column.is-two-thirds
            table.table.is-striped.is-fullwidth.is-bordered.is-narrow
              thead
                tr
                  th Axie
                  th ID
                  th Cost
                  th Sell Price
                  th P/L
              tbody
                tr
                  td Parent 1
                  td
                    a(:href="`https://marketplace.axieinfinity.com/axie/${bp.pair.parent1}/`" target="_blank") {{bp.pair.parent1}}
                  td {{bp.pair.p1b == 0 ? "Bred" : bp.pair.p1b.toFixed(4)}}
                  td {{bp.pair.p1b == 0 ? "0" : bp.pair.p1s == null ? "No record" : bp.pair.p1s.toFixed(4)}}
                  td {{bp.pair.p1b == 0 ? "0" : bp.pair.p1s == null ? (bp.pair.p1b * -1).toFixed(4) : (bp.pair.p1s - bp.pair.p1b).toFixed(4)}}
                tr
                  td Parent 2
                  td
                    a(:href="`https://marketplace.axieinfinity.com/axie/${bp.pair.parent2}/`" target="_blank") {{bp.pair.parent2}}
                  td {{bp.pair.p2b == 0 ? "Bred" : bp.pair.p2b.toFixed(4)}}
                  td {{bp.pair.p2b == 0 ? "0" : bp.pair.p2s == null ? "No record" : bp.pair.p2s.toFixed(4)}}
                  td {{bp.pair.p2b == 0 ? "0" : bp.pair.p2s == null ? (bp.pair.p2b * -1).toFixed(4) : (bp.pair.p2s - bp.pair.p2b).toFixed(4)}}

                tr(v-for="(c, i) in bp.children" :set="avgCost = averageOffspringCost(bp.children, bp.pair.slp_price, bp.pair.axs_price)")
                  td Child {{i+1}}
                  td
                    a(:href="`https://marketplace.axieinfinity.com/axie/${c.axie_id}/`" target="_blank") {{c.axie_id}}
                  td {{avgCost == 0 ? "Need SLP/AXS price" : avgCost.toFixed(4)}}
                  td {{c.sold_price == null ? "No record" : c.sold_price.toFixed(4)}}
                  td {{c.sold_price == null ? (avgCost * -1).toFixed(4) : (c.sold_price - avgCost).toFixed(4)}}
              tfoot
                tr(:set="totals = getTotals(bp)")
                  th
                  th TOTALS
                  th {{totals.cost}}
                  th {{totals.rev}}
                  th {{totals.pl}}
          .column.is-one-third(:set="totals = getTotals(bp)")
            b-field(grouped position="is-centered")
              p.has-text-centered.has-text-weight-bold Bred - {{new Date(bp.pair.breed_timestamp*1000).toLocaleString()}}
            b-field(grouped)
              b-field(label="SLP cost price" label-position="on-border" expanded)
                b-input(v-model="bp.pair.slp_price" type="number" step="0.000000001" expanded)
              b-field(label="AXS cost price" label-position="on-border" expanded)
                b-input(v-model="bp.pair.axs_price" type="number" step="0.000000001" expanded)
            b-field(grouped)
              b-field(label="Baseline expected profit" label-position="on-border" expanded)
                b-input(v-model="bp.pair.expected_base" type="number" step="0.000000001" expanded)
              b-field(label="Adjusted expected profit" label-position="on-border" expanded)
                b-input(v-model="bp.pair.expected_adj" type="number" step="0.000000001" expanded)
            b-field(label="Notes" label-position="on-border" expanded)
              textarea.textarea(rows="3" v-model="bp.pair.notes")
            b-field(grouped position="is-centered")
              b-button(type="is-success" :disabled="savePending" @click="saveChanges(bp.pair)") SAVE DETAILS
            b-field(grouped position="is-centered")
              p.has-text-weight-bold(:class="{'has-text-danger': totals.roi <= 0, 'has-text-success': totals.roi > 0 }") BREED ROI: {{totals.roi.toFixed(2)}}%
        hr


      b-field(grouped position="is-centered")
        b-button(type="is-link" @click="loadMoreResults") LOAD MORE
      p.has-text-centered.has-text-danger {{loadText}}
    //div(v-if="saleData.length > 0")
    //  .flexDiv.nrText.has-text-dark.centerDiv
    //    a
    //      i.fas.fa-chevron-left(v-if="page > 0" @click="changePage(false)")
    //    p.is-unselectable {{page+1}}
    //    a
    //      i.fas.fa-chevron-right(@click="changePage(true)")
    //p.nrText.has-text-centered.has-text-dark {{nrText}}

</template>

<script>

export default {
    name: "BreedTracker",
    data () {
        return {
            breedData: [],
            pl: 0,
            page: 0,
            nrText: null,
            didInitialLoad: false,
            savePending: false,
            loadText: null,
        }
    },
    activated: async function () {
        if (!this.didInitialLoad) {
            this.page = 0;
            this.pl = await this.$api.breedtracker.getTotalPL();
            // console.log(this.pl);
            this.breedData = await this.$api.breedtracker.getRecentBreeds(0);
            if (this.breedData.length == 0) {
                this.loadText = "No results";
            }
            console.log(this.breedData);
        }
    },
    methods: {
        loadMoreResults: async function () {
            this.loadText = null;
            this.page++;
            let newBreeds = await this.$api.breedtracker.getRecentBreeds(this.page);
            if (newBreeds.length == 0) {
                this.loadText = "No more results";
            } else {
                this.loadText = `Loaded ${newBreeds.length} more results`;
            }
            this.breedData = this.breedData.concat(newBreeds);
        },
        // changePage: async function (pageUp) {
        //     this.page += pageUp ? 1 : -1;
        //     // Send the activesearch
        //     this.saleData = [];
        //     this.nrText = null;
        //     this.saleData = await this.$api.sales.getTopSales(this.activeSearch, this.page);
        //     if (this.saleData.length == 0) this.nrText = "No Results";
        // }
        saveChanges: async function (pair) {
            this.savePending = true;
            try {
                await this.$api.breedtracker.savePairData(pair);
            } catch (e) {
                console.log(e);
                console.log(e.response.data)
                if (e.response.data && e.response.data.err) {
                    this.searchError = e.response.data.err;
                } else {
                    this.searchError = `${e.message} - ${e.response.statusText}`;
                }
                this.$buefy.toast.open({
                    message: this.searchError,
                    'type': "is-warning",
                });
            }
            this.savePending = false;
        },
        averageOffspringCost: function (children, slp, axs) {
            if (slp == null || axs == null) return 0;

            let totalAXS = 0;
            let totalSLP = 0;
            for (let a of children) {
                totalAXS += a.axs_burnt;
                totalSLP += a.slp_burnt;
            }
            let totalEth = totalAXS * axs + totalSLP * slp;
            return totalEth / children.length;
        },
        getTotals: function (bp) {
            // Costs
            if (bp.pair.slp_price == null || bp.pair.axs_price == null) {
                return {
                    cost: "Need SLP/AXS price",
                    rev: 0,
                    pl: 0,
                    roi: -100
                }
            }

            let totalAXS = 0;
            let totalSLP = 0;
            for (let a of bp.children) {
                totalAXS += a.axs_burnt;
                totalSLP += a.slp_burnt;
            }
            let totalEth = totalAXS * bp.pair.axs_price + totalSLP * bp.pair.slp_price;
            totalEth += bp.pair.p1b + bp.pair.p2b;

            // Revenue
            let totalRev = 0;
            if (bp.pair.p1b != 0 && bp.pair.p1s != null) totalRev += bp.pair.p1s;
            if (bp.pair.p2b != 0 && bp.pair.p2s != null) totalRev += bp.pair.p2s;

            for (let a of bp.children) {
                if (a.sold_price != null) totalRev += a.sold_price;
            }

            return {
                cost: totalEth.toFixed(4),
                rev: totalRev.toFixed(4),
                pl: (totalRev - totalEth).toFixed(4),
                roi: (totalRev - totalEth) / totalEth * 100
            }
        }
    },
    computed: {

    }
}
</script>

<style scoped>

.nrText {
    font-size: 2em;
    font-weight: bold;
}
.centerDiv {
    width: 100%;
    justify-content: center;
}
.no-margin {
    margin-bottom: 0 !important;
}
</style>

<template lang="pug">
#mainblock
  .container
    p.has-text-danger.is-danger.has-text-centered.has-text-weight-bold(v-if="errText") {{errText}}
    .columns
      .column.is-one-third
        List(list-title="Choose breeding goal (G-Scan)" list-key="gScanHistory" list-index="gScanIndex")
        .padTop(v-if="!prod && $user.grindResults.length > 0")
          nav.panel.is-info
            p.panel-heading GRINDER RESULTS
            a.panel-block(v-for="(p, i) in $user.grindResults" @click="selectSearch(i)" :class="activeStyle(i)")
              p {{`${p.a1.id} x ${p.a2.id}: ${p.adjProfit.toFixed(4)} / ${p.profit.toFixed(4)}`}}
      .column.is-two-thirds
        .box
          nav.level
            .level-item
              p.subtitle.has-text-centered.is-underlined Breeding Goal
          b-field.is-capitalized(v-for="p in Object.keys(selectedParts).sort()" horizontal :label="`${p}:`")
            b-taglist
              b-tag(v-for="x in selectedParts[p].g" type="is-dark" close-type="is-danger")
                .flexDiv
                  img.axieIconSmall(:src="$const.icons[x.cls]")
                  p {{x.name}}


          hr
          b-field(grouped position="is-centered")
            b-field(label="Parent 1 ID" label-position="on-border")
              b-input(placeholder="Any" v-model="parent1" type="number" required)
            b-field(label="Parent 2 ID" label-position="on-border")
              b-input(placeholder="Any" v-model="parent2" type="number" required)
            b-field
              b-button(type="is-primary" :disabled="searchPending" @click="submitBreed") DO THE THING

          hr(v-if="$user.activeGScan && !prod")
          b-field(v-if="$user.activeGScan && !prod" grouped position="is-centered")
            b-field(label="Correct D bonus" label-position="on-border" expanded)
              b-input(placeholder="0" type="number" step="0.00001" v-model="$user.activeGScan.search.correctD" expanded)
            b-field(label="Correct D+R1 bonus" label-position="on-border" expanded)
              b-input(placeholder="0" type="number" step="0.00001" v-model="$user.activeGScan.search.correctDR1" expanded)
            b-field(label="Correct D+R1+R2 bonus" label-position="on-border" expanded)
              b-input(placeholder="0" type="number" step="0.00001" v-model="$user.activeGScan.search.correctDR1R2" expanded)
            b-field
              b-button(type="is-danger" :disabled="searchPending" @click="submitGrind") GRIND
            b-field
              b-button(type="is-danger" :disabled="searchPending" @click="copySearchDown") SET SEARCH
          p.help.is-danger.has-text-centered(v-if="searchError") {{searchError}}




        .box(v-if="searchCopy != null")
          b-field.is-capitalized(v-for="p in Object.keys(copyParts).sort()" horizontal :label="`${p}:`")
            b-taglist
              b-tag(v-for="x in copyParts[p].g" type="is-dark" close-type="is-danger")
                .flexDiv
                  img.axieIconSmall(:src="$const.icons[x.cls]")
                  p {{x.name}}
          hr(v-if="!prod")
          b-field(v-if="!prod" grouped position="is-centered")
            b-field(label="Include axie IDs" label-position="on-border" expanded)
              b-taginput(v-model="regrind.includeList" expanded)
            b-field.flexDiv
              b-switch(v-model="regrind.mineOnly")
          b-field(v-if="!prod" grouped position="is-centered")
            b-field(label="Excluded axie IDs" label-position="on-border" expanded)
              b-taginput(v-model="regrind.filterList" expanded)
          b-field(v-if="!prod" grouped position="is-centered")
            b-field(label="Sort mode" label-position="on-border" expanded)
              b-select(placeholder="Any" v-model="regrind.sortMetric" expanded)
                option(value="adjProfit" key="adjProfit") adjProfit
                option(value="profit" key="profit") profit
                option(value="adjEv" key="adjEv") adjEv
                option(value="pb.goodD" key="pb.goodD") correctD
                option(value="pb.goodDR1" key="pb.goodDR1") correctD+R1
                option(value="pb.perfect" key="pb.perfect") correctD+R1+R2
            b-field.flexDiv
              b-switch(v-model="regrind.unique")
            b-field(label="Min profit" label-position="on-border")
              b-input(placeholder="0" type="number" step="0.0001" v-model="regrind.minProfit")

            b-field
              b-button(type="is-danger" :disabled="searchPending" @click="reGrind") RE-GRIND

          b-field(v-if="!prod" grouped position="is-centered")
            b-field(label="Capital Limit" label-position="on-border" expanded)
              b-input(placeholder="0" type="number" min="0" step="0.0001" v-model="maxCapital" expanded)
            b-field(label="Min breeds" label-position="on-border" expanded)
              b-input(placeholder="0" type="number" min="0" max="7" step="1" v-model="minBreeds" expanded)
            b-field(label="Min base ROI" label-position="on-border" expanded)
              b-input(placeholder="0" type="number" min="0" v-model="minBaseROI" expanded)
            b-field(label="Min extra breed ROI" label-position="on-border" expanded)
              b-input(placeholder="0" type="number" min="0" v-model="minExtraROI" expanded)
            b-field
              b-button(type="is-danger" :disabled="searchPending" @click="maxRoiResults") KNAP MAX ROI

        .box(v-if="knapsack != null")
          p.has-text-centered.is-underlined Optimal ROI breeds
          .flexDiv(v-for="a in knapsackResults.pairs")
            span
              b-tooltip(type="is-dark")
                a(:href="a.gr.a1.full.link" target="_blank") {{a.gr.a1.full.id}}
                template(v-slot:content="")
                  .genegrid
                    p(v-for="p in parseGeneList(a.gr.a1.full.parts)" :style="{ color: p.colour}") {{p.name}}
              span {{` x `}}
              b-tooltip(type="is-dark")
                a(:href="a.gr.a2.full.link" target="_blank") {{a.gr.a2.full.id}}
                template(v-slot:content="")
                  .genegrid
                    p(v-for="p in parseGeneList(a.gr.a2.full.parts)" :style="{ color: p.colour}") {{p.name}}
              span {{` - ${a.bc}B - ${a.avg.cost.toFixed(4)} cost - ${a.avg.baseProfit.toFixed(4)} base - ${a.avg.profit.toFixed(4)} adj - ${a.avg.roi.toFixed(2)}% adj ROI`}}
          span {{`TOTALS: ${knapsackResults.totals.breeds} breeds - ${knapsackResults.totals.cost.toFixed(4)} cost - ${knapsackResults.totals.profit.toFixed(4)} profit - ${(knapsackResults.totals.profit / knapsackResults.totals.cost * 100).toFixed(2)}% average ROI`}}
          br
          hr
          p.has-text-centered.is-underlined Old Profit Max Method
          .flexDiv(v-for="a in profitmaxResults.pairs")
            span
              b-tooltip(type="is-dark")
                a(:href="a.gr.a1.full.link" target="_blank") {{a.gr.a1.full.id}}
                template(v-slot:content="")
                  .genegrid
                    p(v-for="p in parseGeneList(a.gr.a1.full.parts)" :style="{ color: p.colour}") {{p.name}}
              span {{` x `}}
              b-tooltip(type="is-dark")
                a(:href="a.gr.a2.full.link" target="_blank") {{a.gr.a2.full.id}}
                template(v-slot:content="")
                  .genegrid
                    p(v-for="p in parseGeneList(a.gr.a2.full.parts)" :style="{ color: p.colour}") {{p.name}}
              span {{` - ${a.bc}B - ${a.avg.cost.toFixed(4)} cost - ${a.avg.baseProfit.toFixed(4)} base - ${a.avg.profit.toFixed(4)} adj - ${a.avg.roi.toFixed(2)}% adj ROI `}}
          span {{`TOTALS: ${profitmaxResults.totals.breeds} breeds - ${profitmaxResults.totals.cost.toFixed(4)} cost - ${profitmaxResults.totals.profit.toFixed(4)} profit - ${(profitmaxResults.totals.profit / profitmaxResults.totals.cost * 100).toFixed(2)}% average ROI`}}


        p.subtitle.has-text-centered.is-underlined(v-if="breedResults != null") Results
        .box(v-if="breedResults != null")

          b-field(v-if="searchCopy" grouped position="is-centered")
            b-field(label="Correct D bonus" label-position="on-border" expanded)
              b-input(placeholder="0" type="number" step="0.00001" v-model="searchCopy.correctD" expanded)
            b-field(label="Correct D+R1 bonus" label-position="on-border" expanded)
              b-input(placeholder="0" type="number" step="0.00001" v-model="searchCopy.correctDR1" expanded)
            b-field(label="Correct D+R1+R2 bonus" label-position="on-border" expanded)
              b-input(placeholder="0" type="number" step="0.00001" v-model="searchCopy.correctDR1R2" expanded)

          // ADJUSTED ODDS STUFF
          b-field(v-if="searchCopy" grouped)
            b-field(label="Correct D odds" label-position="on-border" expanded)
              b-input(disabled :value="`${(breedResults.pb.goodD * 100).toFixed(2)}%`" expanded)
            b-field(label="Correct D+R1 odds" label-position="on-border" expanded)
              b-input(disabled :value="`${(breedResults.pb.goodDR1 * 100).toFixed(2)}%`" expanded)
            b-field(label="Correct D+R1+R2 odds" label-position="on-border" expanded)
              b-input(disabled :value="`${(breedResults.pb.perfect * 100).toFixed(2)}%`" expanded)

          b-field(grouped)
            b-field(label="ADJUSTED EV PER CHILD" label-position="on-border" expanded  type="is-link")
              b-input(disabled :value="`${adjEv.toFixed(4)}`" expanded)
            b-field(label="ADJUSTED # Breeds" label-position="on-border" expanded type="is-link")
              b-tooltip.has-text-centered(type="is-dark")
                b-input(disabled :value="`${adjIdealBreeds.count}`" expanded)
                template(v-slot:content="")
                  .roiGrid
                    p(v-for="(a) in adjBreedROIs") {{a}}

            b-field(label="ADJUSTED ROI" label-position="on-border" expanded type="is-link")
              b-input(disabled :value="`${(adjProfit / baseCosts * 100).toFixed(2)}%`" expanded)
            b-field(label="ADJUSTED PROFIT" label-position="on-border" expanded type="is-link")
              b-input(disabled :value="`${adjProfit.toFixed(4)}`" expanded)

          b-field(grouped)
            b-field(label="Breeding costs (ETH)" label-position="on-border" expanded)
              b-input(disabled :value="`${adjIdealBreeds.total.toFixed(4)}`" expanded)
            b-field(label="Loss from parents" label-position="on-border" expanded)
              b-input(disabled :value="`${(breedResults.a1.resale.value * $const.axie.MARKET_NET + breedResults.a2.resale.value * $const.axie.MARKET_NET - breedResults.a1.full.price - breedResults.a2.full.price).toFixed(4)}`" expanded)
            b-field(label="Child NET" label-position="on-border" expanded)
              b-input(disabled :value="`${(adjIdealBreeds.count * adjEv * $const.axie.MARKET_NET).toFixed(4)}`" expanded)

          hr
          // BASELINE ODDS
          b-field(grouped)
            b-field(label="BASELINE EV PER CHILD" label-position="on-border" expanded  type="is-primary")
              b-input(type="is-danger" disabled :value="`${baseEv.toFixed(4)}`" expanded)
            b-field(label="BASELINE # Breeds" label-position="on-border" expanded type="is-primary")
              b-tooltip.has-text-centered(type="is-dark")
                b-input(disabled :value="`${baseIdealBreeds.count}`" expanded)
                template(v-slot:content="")
                  .roiGrid
                    p(v-for="(a) in baseBreedROIs") {{a}}
            b-field(label="BASELINE ROI" label-position="on-border" expanded type="is-primary")
              b-input(disabled :value="`${(baseProfit / baseCosts * 100).toFixed(2)}%`" expanded)
            b-field(label="BASELINE PROFIT" label-position="on-border" expanded type="is-primary")
              b-input(disabled :value="`${baseProfit.toFixed(4)}`" expanded)

          b-field(grouped)
            b-field(label="Breeding costs (ETH)" label-position="on-border" expanded)
              b-input(disabled :value="`${baseIdealBreeds.total.toFixed(4)}`" expanded)
            b-field(label="Loss from parents" label-position="on-border" expanded)
              b-input(disabled :value="`${(breedResults.a1.resale.value * $const.axie.MARKET_NET + breedResults.a2.resale.value * $const.axie.MARKET_NET - breedResults.a1.full.price - breedResults.a2.full.price).toFixed(4)}`" expanded)
            b-field(label="Children NET" label-position="on-border" expanded)
              b-input(disabled :value="`${(baseIdealBreeds.count * baseEv * $const.axie.MARKET_NET).toFixed(4)}`" expanded)


          hr
          .columns
            .column
              .box
                b-field.has-text-centered.has-text-weight-bold
                  .control
                    p {{`Parent 1 - ${breedResults.a1.full.id}`}}
                b-field.has-text-centered
                  .control
                    b-tooltip.has-text-centered(type="is-dark")
                      a.axieResult(:href="breedResults.a1.full.link" target="_blank")
                        img.axieIcon(:src="$const.icons[breedResults.a1.full.class.toLowerCase()]")
                        p {{breedResults.a1.full.id}} - G {{breedResults.a1.full.gScore.toFixed(2)}}
                      template(v-slot:content="")
                        .genegrid
                          p(v-for="p in parseGeneList(breedResults.a1.full.parts)" :style="{ color: p.colour}") {{p.name}}
                b-field(grouped)
                  b-field(label="Listed Price" label-position="on-border" expanded )
                    b-input(placeholder="Any" v-model="breedResults.a1.full.price" type="number" step="0.00001" expanded)
                  b-field(label="Resale Price" label-position="on-border" expanded)
                    b-tooltip(:label="breedResults.a1.resale.note")
                      b-input(placeholder="Any" v-model="breedResults.a1.resale.value" type="number" step="0.00001" expanded)
            .column
              .box
                b-field.has-text-centered.has-text-weight-bold
                  .control
                    p {{`Parent 2 - ${breedResults.a2.full.id}`}}
                b-field.has-text-centered
                  .control
                    b-tooltip.has-text-centered(type="is-dark")
                      a.axieResult(:href="breedResults.a2.full.link" target="_blank")
                        img.axieIcon(:src="$const.icons[breedResults.a2.full.class.toLowerCase()]")
                        p {{breedResults.a2.full.id}} - G {{breedResults.a2.full.gScore.toFixed(2)}}
                      template(v-slot:content="")
                        .genegrid
                          p(v-for="p in parseGeneList(breedResults.a2.full.parts)" :style="{ color: p.colour}") {{p.name}}
                b-field(grouped)
                  b-field(label="Listed Price" label-position="on-border" expanded)
                    b-input(placeholder="Any" v-model="breedResults.a2.full.price" type="number" step="0.00001" expanded)
                  b-field(label="Resale Price" label-position="on-border" expanded)
                    b-tooltip(:label="breedResults.a2.resale.note")
                      b-input(placeholder="Any" v-model="breedResults.a2.resale.value" type="number" step="0.00001" expanded)

          b-field.has-text-centered
            a(:href="breedResults.blink" target="_blank") {{breedResults.blink}}

          nav.level
            .level-item
              p.subtitle.has-text-centered.is-underlined Top Outcomes
          b-field(v-for="(a, i) in breedResults.ev.top10" grouped)
            b-field(expanded)
              .control
                .field-label.is-normal {{`#${i+1} - ${a.s} - ${(a.odds * 100).toFixed(2)}%`}}
            b-field(label="Sale Price" label-position="on-border")
              b-tooltip(:label="a.val.note")
                b-input(v-model="a.val.value" step="0.000001" type="number")


          b-field(grouped)
            b-field(label="Top10 Outcome coverage" label-position="on-border" expanded)
              b-input(disabled :value="`${(breedResults.ev.coverage * 100).toFixed(2)}%`" expanded)
            b-field(label="Remaining odds" label-position="on-border" expanded)
              b-input(disabled :value="`${((1 - breedResults.ev.coverage) * 100).toFixed(2)}%`" expanded)
            b-field(label="Remaining odds value" label-position="on-border" expanded)
              b-input(v-model="breedResults.ev.floor" type="number" step="0.001" expanded)

          b-field(grouped)
            b-field(label="SLP price" label-position="on-border" expanded)
              b-input(v-model="breedResults.SLP" type="number" step="0.000000001" expanded)
            b-field(label="AXS price" label-position="on-border" expanded)
              b-input(v-model="breedResults.AXS" type="number" step="0.000000001" expanded)



</template>

<script>
import GSearch from "./GSearch";
import List from "./List";

function BREEDING_COST_GENERATOR (AXS, SLP) {
    return [
        AXS * 0.5 + 1800 * SLP,
        AXS * 0.5 + 2700 * SLP,
        AXS * 0.5 + 4500 * SLP,
        AXS * 0.5 + 7200 * SLP,
        AXS * 0.5 + 11700 * SLP,
        AXS * 0.5 + 18900 * SLP,
        AXS * 0.5 + 30600 * SLP
    ];
}

export default {
    name: "BreedSim",
    data () {
        return {
            errText: null,
            prod: process.env.NODE_ENV == 'production',
            parent1: null,
            parent2: null,
            searchPending: false,
            searchError: null,
            breedResults: null,
            searchCopy: null,
            grindResultIndex: 0,
            regrind: {
                sortMetric: 'adjProfit',
                filterList: [],
                includeList: [],
                unique: false,
                minProfit: null,
                mineOnly: false,
            },
            maxCapital: 3,
            minBreeds: 2,
            minBaseROI: 30,
            minExtraROI: 5,
            knapsack: null,
        }
    },
    components: {
        GSearch,
        List
    },
    watch: {
        "$user.grindResults": function (newVal) {
            if (this.breedResults == null && newVal.length > 0) {
                this.breedResults = newVal[0];
                this.grindResultIndex = 0;
            }
        }
    },
    activated: async function () {
        if (!this.$user.gScanLoaded) {
            try {
                this.errText = null;
                await this.$api.gscan.get_history();
                await this.$api.openSocket();
                this.$user.gScanLoaded = true;
            } catch (e) {
                console.log(e);
                console.log(e.response.data)
                if (e.response.data && e.response.data.err) {
                    this.errText = e.response.data.err;
                } else {
                    this.errText = `${e.message} - ${e.response.statusText}`;
                }
            }
        }
        if (this.$user.grindResults.length > 0) {
            this.grindResultIndex = 0;
            this.breedResults = this.$user.grindResults[0];
        }
    },
    methods: {
        copySearchDown: function () {
            this.searchCopy = JSON.parse(JSON.stringify(this.$user.activeGScan.search));
        },
        submitBreed: async function () {
            if (this.parent1 == null || this.parent2 == null) {
                this.searchError = "Put some parent ID's in...";
                return;
            }

            this.knapsack = null;
            this.searchPending = true;
            this.searchError = null;
            this.breedResults = null;
            this.searchCopy = JSON.parse(JSON.stringify(this.$user.activeGScan.search));
            try {
                this.breedResults = await this.$api.breed.submit(this.searchCopy, this.parent1, this.parent2);
                console.log(this.breedResults);
            } catch (e) {
                console.log(e);
                if (e.response.data) {
                    this.searchError = e.response.data.err;
                } else {
                    this.searchError = `${e.message} - ${e.response.statusText}`;
                }
            }

            this.searchPending = false;
        },
        submitGrind: async function () {
            this.knapsack = null;
            this.searchPending = true;
            this.searchError = null;
            this.breedResults = null;
            this.$user.clearGrindResults();
            this.searchCopy = JSON.parse(JSON.stringify(this.$user.activeGScan.search));

            try {
                await this.$api.grind.submit(this.searchCopy);
            } catch (e) {
                console.log(e);
            }

            this.searchPending = false;
        },
        reGrind: async function () {
            this.knapsack = null;
            this.searchPending = true;
            this.searchError = null;
            this.breedResults = null;
            this.$user.clearGrindResults();

            try {
                await this.$api.grind.regrind(this.regrind);
            } catch (e) {
                console.log(e);
                if (e.response.data) {
                    this.searchError = e.response.data.err;
                } else {
                    this.searchError = `${e.message} - ${e.response.statusText}`;
                }
            }

            this.searchPending = false;
        },
        parseGeneList: function (p) {
            let r = [];
            const genes = ['d', 'r1', 'r2'];
            for (let i of this.$const.axie.allParts) {
                for (let g of genes) {
                    r.push({
                        name: p[i][g].name,
                        colour: this.$const.axie.colours[p[i][g].cls]
                    });
                }
            }
            return r;
        },
        selectSearch: function (i) {
            this.grindResultIndex = i;
            this.breedResults = this.$user.grindResults[i];
        },
        activeStyle: function (i) {
            return {
                'is-active': i == this.grindResultIndex ? true : false
            }
        },
        maxRoiResults: function () {
            this.knapsack = null;
            let bcs = new Array(this.$user.grindResults.length).fill(0);
            let brts = [];
            for (let gr of this.$user.grindResults) {
                brts.push(this.buildBreedRoiTable(gr, this.minBreeds));
            }
            console.log(brts);

            let spent = this.recursiveBreeds(bcs, brts, this.maxCapital);
            console.log(`TOTAL SPENT: ${spent}`);
            // console.log(bcs);

            this.knapsack = {
                limit: this.maxCapital,
                spent,
                bcs,
                brts,
            }
        },
        recursiveBreeds: function (bcs, brts, limit, spent = 0.000) {
            let nextBreedRois = [];

            for (let i = 0; i < bcs.length; i++) {
                if (bcs[i] == 0) {
                    // Find the max average ROI result
                    let m = brts[i].average.reduce((ac, cv) => {
                        if (cv.roi < this.minBaseROI) return ac;
                        if (ac == null) return cv;
                        if (cv.roi > ac.roi) return cv;
                        return ac;
                    }, null);

                    nextBreedRois.push({
                        i,
                        m
                    });
                } else {
                    // Get the ROI for the next breed
                    let m = null;
                    if (bcs[i] < brts[i].single.length && brts[i].single[bcs[i]].roi >= this.minExtraROI) {
                        m = brts[i].single[bcs[i]];
                    }
                    nextBreedRois.push({
                        i,
                        m
                    });
                }
            }

            // Get the biggest one
            let bestBreed = nextBreedRois.reduce((ac, cv) => {
                if (ac.m == null) return cv;
                if (cv.m != null && cv.m.roi > ac.m.roi) return cv;
                return ac;
            });

            // console.log("Best result");
            // console.log(bestBreed);

            // Update arrays and spin again
            if (bestBreed.m != null && bestBreed.m.roi > 0) {
                // Update arrays
                bcs[bestBreed.i] = bestBreed.m.bc;
                spent +=  bestBreed.m.cost;

                if (spent > limit) {
                    console.log("HIT CAPITAL LIMIT");
                    return spent;
                } else {
                    return this.recursiveBreeds(bcs, brts, limit, spent);
                }

            } else {
                console.log("RAN OUT OF PROFITABLE BREEDS");
                return spent;
            }
        },
        buildBreedRoiTable: function (gr, minBreeds) {
            const AXS = parseFloat(gr.AXS);
            const SLP = parseFloat(gr.SLP);
            const BREEDING_COSTS = BREEDING_COST_GENERATOR(AXS, SLP);


            let brt = {
                single: [],
                average: [],
            };

            // Adj EV calc again
            let ev = 0;
            for (let a of gr.ev.top10) {
                ev += a.val.value * a.odds;
            }
            ev += (1 - gr.ev.coverage) * gr.ev.floor;
            let baseEv = ev;
            if (this.searchCopy != null && this.searchCopy.correctD != null) {
                ev += this.searchCopy.correctD * gr.pb.goodD;
            }
            if (this.searchCopy != null && this.searchCopy.correctDR1 != null) {
                ev += this.searchCopy.correctDR1 * gr.pb.goodDR1;
            }
            if (this.searchCopy != null && this.searchCopy.correctDR1R2 != null) {
                ev += this.searchCopy.correctDR1R2 * gr.pb.perfect;
            }
            // Market net now
            ev = ev * this.$const.axie.MARKET_NET;
            baseEv = baseEv * this.$const.axie.MARKET_NET;
            // Baseline EV

            const parents = gr.a1.resale.value * this.$const.axie.MARKET_NET + gr.a2.resale.value * this.$const.axie.MARKET_NET - gr.a1.full.price - gr.a2.full.price;


            for (let i = 0; i < BREEDING_COSTS.length; i++) {
                if (ev > BREEDING_COSTS[i]) {
                    // Averages, only include if > minBreeds
                    if (i + 1 >= minBreeds) {
                        let c = BREEDING_COSTS.slice(0, i+1).reduce((ac, cv) => ac + cv, 0.00);
                        brt.average.push({
                            bc: i+1,
                            cost: c + gr.a1.full.price + gr.a2.full.price,
                            roi: (ev*(i+1) + parents - c) / c * 100,
                            profit: (ev*(i+1) + parents - c),
                            baseProfit: (baseEv*(i+1) + parents - c),
                        });
                    }

                    // Single breeds
                    brt.single.push({
                        bc: i+1,
                        cost: BREEDING_COSTS[i],
                        roi: (ev - BREEDING_COSTS[i]) / BREEDING_COSTS[i] * 100
                    });
                }
            }

            return brt;
        }
    },
    computed: {
        selectedParts: function () {
            let d = {};
            if (this.$user.activeGScan != null) {
                for (let g of Object.keys(this.$user.activeGScan.search.goalParts)) {
                    for (let p of this.$user.activeGScan.search.goalParts[g]) {
                        // Check/create type (horn, mouth etc)
                        if (d[p.type] == null) {
                            d[p.type] = {};
                        }
                        // Check/create gene (d, r1, r2)
                        if (d[p.type][g] == null) {
                            d[p.type][g] = [];
                        }
                        // Actually add it
                        d[p.type][g].push({
                            name: p.name,
                            cls: p.class,
                            id: p.partId
                        });
                    }
                }
            }
            return d;
        },
        copyParts: function () {
            let d = {};
            if (this.searchCopy != null) {
                for (let g of Object.keys(this.searchCopy.goalParts)) {
                    for (let p of this.searchCopy.goalParts[g]) {
                        // Check/create type (horn, mouth etc)
                        if (d[p.type] == null) {
                            d[p.type] = {};
                        }
                        // Check/create gene (d, r1, r2)
                        if (d[p.type][g] == null) {
                            d[p.type][g] = [];
                        }
                        // Actually add it
                        d[p.type][g].push({
                            name: p.name,
                            cls: p.class,
                            id: p.partId
                        });
                    }
                }
            }
            return d;
        },
        baseIdealBreeds: function () {
            const AXS = parseFloat(this.breedResults.AXS);
            const SLP = parseFloat(this.breedResults.SLP);
            const BREEDING_COSTS = BREEDING_COST_GENERATOR(AXS, SLP);

            let goodBreeds = BREEDING_COSTS.filter(b => b < this.baseEv*this.$const.axie.MARKET_NET);
            let totalCost = goodBreeds.reduce((a, b) => a + b, 0.00);
            return {
                total: (totalCost * -1),
                count: goodBreeds.length,
            }
        },
        baseEv: function () {
            let ev = 0;
            for (let a of this.breedResults.ev.top10) {
                ev += a.val.value * a.odds;
            }
            ev += (1 - this.breedResults.ev.coverage) * this.breedResults.ev.floor;
            return ev;
        },
        baseProfit: function () {
            let p = 0;
            p += this.baseEv * this.baseIdealBreeds.count * this.$const.axie.MARKET_NET + this.baseIdealBreeds.total;
            p += this.breedResults.a1.resale.value * this.$const.axie.MARKET_NET + this.breedResults.a2.resale.value * this.$const.axie.MARKET_NET - this.breedResults.a1.full.price - this.breedResults.a2.full.price;
            return p;
        },
        baseCosts: function () {
            let p = 0;
            p += parseFloat(this.breedResults.a1.full.price) + parseFloat(this.breedResults.a2.full.price) - this.baseIdealBreeds.total;
            return p;
        },
        adjIdealBreeds: function () {
            const AXS = parseFloat(this.breedResults.AXS);
            const SLP = parseFloat(this.breedResults.SLP);
            const BREEDING_COSTS = BREEDING_COST_GENERATOR(AXS, SLP);

            let goodBreeds = BREEDING_COSTS.filter(b => b < this.adjEv*this.$const.axie.MARKET_NET);
            let totalCost = goodBreeds.reduce((a, b) => a + b, 0.00);
            return {
                total: (totalCost * -1),
                count: goodBreeds.length,
            }
        },
        adjEv: function () {
            let ev = this.baseEv;
            if (this.searchCopy != null && this.searchCopy.correctD != null) {
                ev += this.searchCopy.correctD * this.breedResults.pb.goodD;
            }
            if (this.searchCopy != null && this.searchCopy.correctDR1 != null) {
                ev += this.searchCopy.correctDR1 * this.breedResults.pb.goodDR1;
            }
            if (this.searchCopy != null && this.searchCopy.correctDR1R2 != null) {
                ev += this.searchCopy.correctDR1R2 * this.breedResults.pb.perfect;
            }

            return ev;
        },
        adjProfit: function () {
            let p = 0;
            p += this.adjEv * this.adjIdealBreeds.count * this.$const.axie.MARKET_NET + this.adjIdealBreeds.total;
            p += this.breedResults.a1.resale.value * this.$const.axie.MARKET_NET + this.breedResults.a2.resale.value * this.$const.axie.MARKET_NET - this.breedResults.a1.full.price - this.breedResults.a2.full.price;
            return p;
        },
        adjBreedROIs: function () {
            const AXS = parseFloat(this.breedResults.AXS);
            const SLP = parseFloat(this.breedResults.SLP);
            const BREEDING_COSTS = BREEDING_COST_GENERATOR(AXS, SLP);

            const parents = this.breedResults.a1.resale.value * this.$const.axie.MARKET_NET + this.breedResults.a2.resale.value * this.$const.axie.MARKET_NET - this.breedResults.a1.full.price - this.breedResults.a2.full.price;
            let x = ['B', 'Cost', 'ROI', 'avgROI', 'Axies', 'Profit'];
            for (let i = 0; i < BREEDING_COSTS.length; i++) {
                if (this.adjEv*this.$const.axie.MARKET_NET > BREEDING_COSTS[i]) {
                    // Breed number
                    x.push((i+1));
                    // This breed cost
                    x.push(BREEDING_COSTS[i].toFixed(4));
                    // This breed's ROI
                    x.push(`${((this.adjEv*this.$const.axie.MARKET_NET - BREEDING_COSTS[i]) / BREEDING_COSTS[i] * 100).toFixed(2)}%`);
                    //Calculate total breeding cost so far
                    let c = BREEDING_COSTS.slice(0, i+1).reduce((ac, cv) => ac + cv, 0.00);
                    // Average ROI
                    x.push(`${((this.adjEv*(i+1)*this.$const.axie.MARKET_NET + parents - c) / c * 100).toFixed(2)}%`)
                    // Axie growth
                    x.push(`${-100 + (i+1) * 50}%`)
                    // Profit
                    x.push(`${(this.adjEv * (i + 1)*this.$const.axie.MARKET_NET + parents - c).toFixed(4)}`);

                    // x.push(`${i+1}: ${BREEDING_COSTS[i].toFixed(4)} - ${((this.adjEv - BREEDING_COSTS[i]) / BREEDING_COSTS[i] * 100).toFixed(2)}% - ${-100 + (i+1) * 50}%`);
                }
            }

            return x;
        },
        baseBreedROIs: function () {
            const AXS = parseFloat(this.breedResults.AXS);
            const SLP = parseFloat(this.breedResults.SLP);
            const BREEDING_COSTS = BREEDING_COST_GENERATOR(AXS, SLP);

            const parents = this.breedResults.a1.resale.value * this.$const.axie.MARKET_NET + this.breedResults.a2.resale.value * this.$const.axie.MARKET_NET - this.breedResults.a1.full.price - this.breedResults.a2.full.price;
            let x = ['B', 'Cost', 'ROI', 'avgROI', 'Axies', 'Profit'];
            for (let i = 0; i < BREEDING_COSTS.length; i++) {
                if (this.baseEv*this.$const.axie.MARKET_NET > BREEDING_COSTS[i]) {
                    // Breed number
                    x.push((i+1));
                    // This breed cost
                    x.push(BREEDING_COSTS[i].toFixed(4));
                    // This breed's ROI
                    x.push(`${((this.baseEv*this.$const.axie.MARKET_NET - BREEDING_COSTS[i]) / BREEDING_COSTS[i] * 100).toFixed(2)}%`);
                    //Calculate total breeding cost so far
                    let c = BREEDING_COSTS.slice(0, i+1).reduce((ac, cv) => ac + cv, 0.00);
                    // Average ROI
                    x.push(`${((this.baseEv*(i+1)*this.$const.axie.MARKET_NET + parents - c) / c * 100).toFixed(2)}%`)
                    // Axie growth
                    x.push(`${-100 + (i+1) * 50}%`)
                    // Profit
                    x.push(`${(this.baseEv * (i + 1)*this.$const.axie.MARKET_NET + parents - c).toFixed(4)}`);

                    // x.push(`${i+1}: ${BREEDING_COSTS[i].toFixed(4)} - ${((this.adjEv - BREEDING_COSTS[i]) / BREEDING_COSTS[i] * 100).toFixed(2)}% - ${-100 + (i+1) * 50}%`);
                }
            }

            return x;
        },
        knapsackResults: function (){
            if (this.knapsack == null) return [];

            let pairs = [];
            let totals = {
                breeds: 0,
                cost: 0.00,
                profit: 0.00
            }

            for (let i = 0; i < this.knapsack.bcs.length; i++) {
                if (this.knapsack.bcs[i] == 0) continue;

                let avg = this.knapsack.brts[i].average.find(x => x.bc == this.knapsack.bcs[i]);
                pairs.push({
                    bc: this.knapsack.bcs[i],
                    avg,
                    gr: this.$user.grindResults[i],
                });


                totals.breeds += this.knapsack.bcs[i];
                totals.cost += avg.cost;
                totals.profit += avg.profit;

            }

            return {
                totals,
                pairs,
            }
        },
        profitmaxResults: function (){
            if (this.knapsack == null) return [];

            let pairs = [];
            let totals = {
                breeds: 0,
                cost: 0.00,
                profit: 0.00
            }

            for (let i = 0; i < this.knapsack.bcs.length; i++) {
                if (this.knapsack.brts[i].average.length == 0) continue;

                // Check max first
                let max = this.knapsack.brts[i].average[this.knapsack.brts[i].average.length - 1];
                if (max.roi < 0) continue;

                if (totals.cost + max.cost > this.knapsack.limit) {
                    // Loop over and take the cheapest breed that exceeds the limit
                    for (let j = 0; j < this.knapsack.brts[i].average.length; j++) {
                        let x = this.knapsack.brts[i].average[j];
                        if (x.cost + totals.cost > this.knapsack.limit) {
                            pairs.push({
                                bc: x.bc,
                                avg: x,
                                gr: this.$user.grindResults[i]
                            });

                            totals.cost += x.cost;
                            totals.breeds += x.bc;
                            totals.profit += x.profit;
                            break;
                        }
                    }
                    break;
                } else {
                    pairs.push({
                        bc: max.bc,
                        avg: max,
                        gr: this.$user.grindResults[i]
                    });
                    totals.cost += max.cost;
                    totals.breeds += max.bc;
                    totals.profit += max.profit;
                }
            }

            return {
                totals,
                pairs,
            }
        }


    }
}

</script>

<style scoped>
.partSection {
    padding-bottom: 1rem;
}
.padTop {
    padding-top: 1rem;
}
.roiGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    column-gap: 5px;
}
</style>

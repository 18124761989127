<template lang="pug">
#mainblock
  .container
    nav.panel.is-info
      .panel-heading
        .level
          .level-left
            .level-item
              p {{listTitle}}
          .level-right
            .level-item
              i.fas(:class="{'fa-angle-double-up': !showItems, 'fa-angle-double-down': showItems}" @click="toggleItems")

      a.panel-block(v-if="showItems" v-for="(p, i) in $user[listKey]" :class="activeStyle(i)")
        .flexSpread
          .flexDiv.maxp(@click="selectSearch(i)")
            img.axieIcon(v-for="i in classIcons(p)" :src="i")
            p.maxp {{listNameString(p)}}
          button.delete.is-small(@click="deleteItem(p)")

</template>

<style scoped>

.flexSpread {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.maxp {
    width: 100%;
}

</style>


<script>
export default {
    name: "List",
    props: ['listKey', 'listTitle', 'listIndex'],
    data () {
        return {
            showItems: true,
        }
    },
    methods: {
        toggleItems: function () {
            this.showItems = !this.showItems;
        },
        listNameString: function (p) {
            let s = "";
            if (!Array.isArray(p.search.classes)) {
                s += "(OLD, NEEDS REDO) "
            }

            if (p.search.searchName != null && p.search.searchName != "") {
                s += p.search.searchName;
            } else {
                let f = p.search.parts.d.map(a => a.name).join(', ');
                s += `${p.search.classes.length == 0 ? 'Any' : p.search.classes.join(', ')} - ${f}`;
            }

            if (p.results == null) {
                s += " (no res)";
            } else {
                s += ` (${p.results.axies.length})`;
            }
            return s;
        },
        selectSearch: function (i) {
            this.$user[this.listIndex] = i;
        },
        activeStyle: function (i) {
            return {
                'is-active': i == this.$user[this.listIndex] ? true : false
            }
        },
        classIcons: function (p) {
            return p.search.classes.map(c => this.$const.icons[`${c.toLowerCase()}`]);
        },
        deleteItem: async function (p) {
            if (this.listKey == 'searchHistory') {
                try {
                    await this.$api.delete_search(p.search);
                } catch (e) {
                    console.log("Failed to delete search from icon");
                    console.log(e);
                }
            }

            if (this.listKey == 'gScanHistory') {
                try {
                    await this.$api.gscan.delete_gscan(p.search);
                } catch (e) {
                    console.log("Failed to delete gscan from icon")
                    console.log(e);
                }
            }
        },
    },
    computed: {

    }
}
</script>


<template lang="pug">
#mainblock
  .container
    p.has-text-danger.is-danger.has-text-centered.has-text-weight-bold(v-if="errText") {{errText}}
    .box
      Search
    .columns
      .column.is-one-third
        List(list-title="Recent Searches" list-key="searchHistory" list-index="listIndex")
      .column.is-one-third
        .box
          Results
      .column.is-one-third
        .box
          RecentListings




</template>

<script>
import List from "./List";
import Results from "./Results";
import Search from "./Search";
import RecentListings from "./RecentListings";

export default {
    name: "Index",
    data () {
        return {
            errText: null,
        }
    },
    components: {
        List,
        Results,
        Search,
        RecentListings
    },
    activated: async function () {
        if (!this.$user.searchLoaded) {
            try {
                this.errText = null;
                this.$log.debug("Getting search history and opening websocket");
                await this.$api.get_history();
                await this.$api.openSocket();
                this.$user.searchLoaded = true;
            } catch (e) {
                console.log(e);
                console.log(e.response.data)
                if (e.response.data && e.response.data.err) {
                    this.errText = e.response.data.err;
                } else {
                    this.errText = `${e.message} - ${e.response.statusText}`;
                }
            }
        }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
